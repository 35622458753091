<div class="ui-grid-col-12" style="padding-top: 2%; padding-bottom: 25px;">
  <div [style.display]="showInboxTransactions ? 'contents' : 'none'">
    <h2 class="title" style="padding-top: 2%;">Transaksjoner som trenger manuell behandling</h2>
    <my-transactions [displayMode]="displayModeDict.inbox" (transactionsNonEmpty)="showInboxTransactions = $event"></my-transactions>
  </div>
  <h2 *ngIf="!escaliMode" class="title" style="padding-top: 2%;">Portefølje</h2>
  <my-portfolio *ngIf="!escaliMode" [displayMode]="displayModeDict.all"></my-portfolio>
</div>
<div class="ui-grid-col-12" style="padding-bottom: 25px;">
  <h2 class="title">Siste transaksjoner</h2>
  <my-transactions [displayMode]="displayModeDict.all"></my-transactions>
</div>
<div *ngIf="!escaliMode" class="ui-grid-col-6" style="padding-right: 10px;">
  <h2 class="title">Resultat</h2>
  <my-balance
    [displayResultAccounts]="true"
    [displayColumns]="['accountNumber', 'description', 'currentBalance']"
    [globalFilter]="true">
  </my-balance>
</div>
<div *ngIf="!escaliMode" class="ui-grid-col-6" style="padding-left: 10px;">
  <h2 class="title">Balanse</h2>
  <my-balance
    [displayColumns]="['accountNumber', 'description', 'currentBalance']"
    [globalFilter]="true">
  </my-balance>
</div>

