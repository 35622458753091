<h5 *ngIf="data.success" mat-dialog-title class="title" style="text-align: center; font-size: x-large">
  {{ data.deleteAttempt ? 'Transaksjon slettet' : 'Transaksjon lagret' }}
</h5>
<h5 *ngIf="!data.success" mat-dialog-title class="title" style="text-align: center; font-size: x-large; color: red;">
  {{ data.deleteAttempt ? 'Feil ved sletting av transaksjon' : 'Feil ved lagring av transaksjon' }}
</h5>
<div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix" style="border: none; text-align: center">
  <button mat-button mat-dialog-close
    *ngIf="data.success && data.transactionId"
    (click)="openTransactionDetails()"
    class="theme-button" type="button">
    <mat-icon aria-label="Vis transaksjon">open_in_new</mat-icon>Vis transaksjon
  </button>
  <button mat-button mat-dialog-close class="theme-button" style="margin-left: 10px;" type="button" (click)="onRetry()">
    <mat-icon aria-label="Prøv igjen">settings_backup_restore</mat-icon>{{retryButtonLabel}}
  </button>
  <button mat-button mat-dialog-close class="theme-button" style="margin-left: 10px;" type="button">
    <mat-icon aria-label="Lukk">close</mat-icon>Lukk
  </button>
</div>
