import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AbstractTableComponent } from '../abstract-table.component';
import { AccountsService } from '../accounts/accounts.service';
import { DisplayMode, DisplayModeDict } from '../domain/display-mode';
import { Filter } from '../domain/filter';
import { Page } from '../domain/page';
import { RequestArguments } from '../domain/request-arguments';
import { TableColumn } from "../domain/table-column";
import { Transaction } from '../domain/transaction';
import { GlobalsService } from '../globals.service';
import { AddTransactionComponent } from './add-transaction/add-transaction.component';

import { TransactionService } from './transaction.service';

@Component({
  selector : 'my-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['../abstract-table.component.scss']
})

export class TransactionsComponent extends AbstractTableComponent<Transaction> implements OnInit {
  public displayModeDict = new DisplayModeDict();
  public tableColumns: Array<TableColumn> = [
    {header : 'TYPE', field : 'transactionType', type : 'transactionType'},
    {header : 'MOTTATT', field : 'registeredDate', type : 'datetime'},
    {header : 'HANDELSDATO', field : 'transactionDate', type : 'date'},
    {header : 'SLUTTSEDDEL ID', field : 'note.externalId'},
    {header : 'VALUTA', field : 'transactionCurrency'},
    {header : 'BELØP', field : 'amount', type : 'integer'},
    {header : 'MEGLER', field : 'broker.name'},
    {header : 'EPOST', field : 'recipient'},
    {header : 'REDIGER', field : 'edit', type : 'editButton'}
  ];
  public rowsPerPage = 15;
  public showPaginator = false;
  public isinParam = null;
  public totalRecords: number;
  public hasError = false;
  public isComplete = false;
  public transactions: Array<Transaction> = [];
  public globalFilterVal: string;
  public filterSubject = new Subject<Array<Filter>>();
  public defaultPage = new Page<Transaction>(15, 0, 'registeredDate', 'desc');
  public pageSubject = new BehaviorSubject<Page<Transaction>>(this.defaultPage);
  public tableStyleClass;
  public orgOptions: any = [{'name': "first"}, {"name": "second"}];
  public selectedOrg = null;

  @Input() displayMode = DisplayMode.ALL;
  @Input() transactionToShow?: Transaction;
  @Input() transactionId?: number;
  @Output() transactionsNonEmpty = new EventEmitter<boolean>();

  constructor(private transactionService: TransactionService,
              private accountsService: AccountsService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              private router: Router,
              public globalsService: GlobalsService
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    if (this.displayMode === DisplayMode.ALL || this.displayMode === DisplayMode.SECURITY) {
      this.tableStyleClass = "customStripes selectable-table";
    }
    else {
      this.tableStyleClass = "customStripes";
    }
    if (this.globalsService.escaliMode) {
      this.tableColumns.splice(7, 0, {header: 'KLIENT', field: 'organization.name'});
      this.accountsService.getCustomerSettings().subscribe(res => this.orgOptions = res.organization);
    }
    if (this.displayMode === DisplayMode.TRANSACTION) {
      this.tableColumns.splice(2, 0, {header : 'VERDIPAPIR', field : 'portfolioItem.security.name'});
      this.tableColumns.splice(7, 0, {header : 'KURTASJE', field : 'fees', type : 'integer'});
      this.tableColumns.splice(7, 0, {header : 'PRIS PER ENHET', field : 'pricePerUnit', type : 'decimal'});
      this.tableColumns.splice(7, 0, {header : 'ANTALL', field : 'units', type : 'integer'});
      if (this.transactionToShow) {
        this.transactions = [this.transactionToShow];
      }
      else {
        this.transactionService.getSingleTransaction(this.transactionId).subscribe(transaction => this.transactions = [transaction]);
      }
      return;
    }
    else if (this.displayMode === DisplayMode.SECURITY) {
      this.isinParam = this.route.snapshot.params.isin;
    }
    else if (this.displayMode === DisplayMode.INBOX) {
      this.tableColumns.splice(2, 0, {header : 'VERDIPAPIR', field : 'portfolioItem.security.name', type : 'assetName'});
      this.defaultPage.sortField = 'registeredDate';
    }
    else {
      this.tableColumns.splice(2, 0, {header : 'VERDIPAPIR', field : 'portfolioItem.security.name', type : 'assetName'});
    }

    combineLatest([this.pageSubject, this.filterSubject.pipe(startWith(this.getFilters()), debounceTime(400), distinctUntilChanged())])
      .pipe(
        tap(() => {
          this.isComplete = false;
          this.hasError = false;
        }),
        map(([page, filters]) => new RequestArguments(page, filters)),
        switchMap(args => this.transactionService.getTransactions(args))
      ).subscribe(
      transactionPage => {
        this.totalRecords = transactionPage.totalElements;
        this.showPaginator = this.showPaginator || this.totalRecords > this.rowsPerPage;  // Prevents hiding of paginator after changing rowsPerPage
        this.transactions = transactionPage.content;
        this.hasError = false;
        this.isComplete = true;
        this.transactionsNonEmpty.emit(!!transactionPage.content);
      },
      error => {
        console.log("Error getting transactions:");
        console.log(error);
        this.transactions = [];
        this.hasError = true;
        this.isComplete = true;
        this.transactionsNonEmpty.emit(false);
      }
    );

    // Underlying data has changed => reload data from service
    this.transactionService.transactionsChangedNotifier
      .subscribe(() => this.lazyLoadTransactions({}));
  }

  lazyLoadTransactions(event: LazyLoadEvent) {
    this.rowsPerPage = event.rows;
    this.filterSubject.next(this.getFilters(event.globalFilter));

    // This wierd check is due to event.sortOrder being set to 1/asc even though user hasn't overridden initial sort
    const order = event.sortField ? event.sortOrder : this.defaultPage.sortOrder;

    this.pageSubject.next(new Page(event.rows, event.first, event.sortField || this.defaultPage.sortField, order));
  }

  openTransactionEditDialog(rowdata) {
    const diagConfig = (transaction: Transaction) => ({data: {transaction: transaction, showAsDialog: true}, width: "90vw", minWidth: "800px", maxWidth: "95vw", height: "95vh", maxHeight: "95vh"});
    if (this.displayMode !== DisplayMode.TRANSACTION) {
      this.transactionService.getSingleTransaction(rowdata.id)
        .subscribe(
          transaction => {
            rowdata = transaction;
            this.dialog.open(AddTransactionComponent, diagConfig(rowdata));
          }
        );
    }
    else {
      this.dialog.open(AddTransactionComponent, diagConfig(rowdata));
    }
  }

  handleRowSelect(rowData: Transaction) {
    if (this.displayMode === DisplayMode.SECURITY) {
      this.openTransactionDetails(rowData.id);
    }
    else if (this.displayMode === DisplayMode.ALL) {
      this.router.navigateByUrl('/details/' + rowData.portfolioItem.security.isin);
    }
  }

  getFilters(globalFilter?: string, ignoreGlobalFilter = false): Array<Filter> {
    if (!ignoreGlobalFilter) {
      this.globalFilterVal = globalFilter;
    }
    const filters = [
      new Filter('global', undefined, this.globalFilterVal),
      new Filter('portfolioItem.security.isin', undefined, this.isinParam),
      new Filter('organization.id', undefined, this.selectedOrg?.id),
      new Filter('transactionStatus', undefined, this.displayMode === DisplayMode.INBOX ? 'REQUIRES_ACTION' : 'ACTIVE')
    ];
    this.filterSubject.next(filters);
    return filters;
  }

  getRowClasses(rowData: Transaction): Array<String> {
    const classes = ['withEditButton'];
    if (rowData.transactionType === 'PURCHASE') {
      classes.push('PURCHASE');
    }
    else if (rowData.transactionType === 'SALE') {
      classes.push('SALE');
    }
    else if (rowData.transactionType === 'DIVIDEND') {
      classes.push('DIVIDEND');
    }
    return classes;
  }

  exportTransactionsToExcel() {
    // Huge page used in order to DL all relevant transactions in one go
    const exportColumns = this.tableColumns.filter(col => col.type !== "editButton");  // Don't export the empty edit column
    let tableName = "transaksjoner";
    if (this.displayMode === DisplayMode.SECURITY) {
      exportColumns.splice(1, 0, {header : 'VERDIPAPIR', field : 'portfolioItem.security.name'});  // Export securityName even in displayMode SECURITY
      tableName += " " + this.isinParam;
    }
    exportColumns.splice(2, 0, {header : 'ISIN', field : 'portfolioItem.security.isin'});
    const currentPage = this.pageSubject.getValue();
    const hugePage = new Page<Transaction>(9999999, 0, currentPage.sortField, currentPage.sortOrder);
    this.exportDataToExcel(this.transactionService.getTransactions(new RequestArguments(hugePage, this.getFilters(null, true))), tableName, exportColumns);
  }
}
