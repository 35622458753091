import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DisplayModeDict } from './domain/display-mode';
import { GlobalsService } from './globals.service';

@Component({
  selector: 'my-dashboard',
  templateUrl: './dashboard.component.html'
})

export class DashboardComponent {
  public escaliMode = this.globalsService.escaliMode;
  public showInboxTransactions = false;
  public displayModeDict = new DisplayModeDict();

  constructor(private globalsService: GlobalsService, private titleService: Title) {
    this.titleService.setTitle(this.globalsService.escaliMode || this.globalsService.bookkeeperMode ? "Verdipapir" : "Secbase");
  }
}
