export const environment = {
  production: true,
  test: false,
  hmr: false,
  apiUrl: 'https://beta.secbase.no',
  auth0: {
    clientID: '6txfKcctfx82sDNrHMXaY3cej0OThYir',
    domain: 'secbase.eu.auth0.com',
    audience: 'http://localhost:3010',  // Audience is the "name" of the API and does not have to match the actual url of the API.
    customClaimsNamespace: 'http://login.secbase.no/'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


