<div>
  <h5 mat-dialog-title class="title" style="text-align: center; font-size: x-large">
    VELG VERDIPAPIR
  </h5>
  <mat-dialog-content>
    <p-table
      #securitiesTable
      [value]="securities"
      [columns]="tableColumns"
      [responsive]="true"
      [autoLayout]="true"
      [paginator]="totalRecords > rowsPerPage"
      [totalRecords]="totalRecords"
      [rowsPerPageOptions]="[15, 20, 40, 60]"
      [pageLinks]="5"
      [rows]="rowsPerPage"
      [lazy]="true"
      [lazyLoadOnInit]="false"
      [rowHover]="true"
      (onLazyLoad)="lazyLoadSecurities($event)"
      (onRowSelect)="onSecuritySelection($event)"
      selectionMode="single"
      paginatorDropdownAppendTo="body"
      tableStyleClass="customStripes selectable-table"
      styleClass="defaultRowStyleClass"
    >
      <ng-template pTemplate="caption">
        <div class="header-item" style="text-align: left">
          <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
          <input class="globalfilter" type="text" pInputText size="50" placeholder="Søk"
            (input)="securitiesTable.filterGlobal($event.target.value, 'contains')" style="width:auto"
          >
        </div>
        <div class="header-item">
          <button mat-button class="theme-button" type="button" (click)="openAddInstrumentDialog()">
            <mat-icon aria-label="Nytt verdipapir">open_in_new</mat-icon>  Legg til nytt verdipapir
          </button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col1 of columns">
            <!-- Sortable header col -->
            <ng-container *ngIf="securities?.length > 1">
              <th [pSortableColumn]="col1.field">
                <p-sortIcon [field]="col1.field"></p-sortIcon>
                {{col1.header}}
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col2 of columns" [ngSwitch]="col2.type">
            <p>
              {{getCellData(rowData, col2.field)}}
            </p>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="tableColumns.length">
            {{hasError ? 'Feil ved henting av data' : isComplete ? 'Ingen data funnet' : 'Henter data...'}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </mat-dialog-content>
</div>
