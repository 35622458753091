<div id="component-container">
    <h2 id="note-header" class="title">Sluttseddel</h2>
    <div
      class="note-container"
      *ngIf="isPdf && sanitizedPdfUrl; else textNote"
    >
        <ng-container *ngIf="browserNativePdfSupport; else fallbackPdf">
            <object [attr.data]="sanitizedPdfUrl" type="application/pdf" id="pdf">
                <embed [attr.src]="sanitizedPdfUrl">
            </object>
        </ng-container>
        <ng-template #fallbackPdf>
            <!-- Fallback used for IE and Edge -->
            <pdf-viewer
              [src]="unsanitizedPdfUrl"
              [render-text]="true"
            ></pdf-viewer>
        </ng-template>
    </div>
    <ng-template #textNote>
        <div class="note-container" *ngIf="!isPdf && !hasError && isComplete; else waitingMessage">
            <pre lang="no">{{_note.content}}</pre>
        </div>
    </ng-template>

    <ng-template #waitingMessage>
        <h5>{{ hasError ? 'Feil ved henting av sluttseddel' : isComplete ? 'Ingen sluttseddel funnet' : 'Henter sluttseddel...' }}</h5>
    </ng-template>
</div>
