import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

import { Observable, of, Subject } from 'rxjs';
import { catchError, map, share, shareReplay, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../authentication/auth.service';
import { BalanceService } from '../balance/balance.service';

import { Customer, Organization, OrganizationMapping } from '../domain/customer';
import { PortfolioService } from '../portfolio/portfolio.service';
// import { StockSplitsService } from '../stock-splits/stock-splits.service';
import { TransactionService } from '../transactions/transaction.service';

const API_URL = environment.apiUrl;
const CACHE_SIZE = 1;

@Injectable()
export class AccountsService {
  private availableCustomersCache: Observable<Array<Customer>>;
  private availableCustomersCacheRefresh = new Subject<void>();
  private customerSettingsCache: Observable<Customer>;
  private customerSettingsCacheRefresh = new Subject<void>();
  private getOrgEndpoint = (isOrganizationType: boolean) => isOrganizationType ? `/customer/settings/organizations` : `/customer/settings/orgMappings`;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private portfolioService: PortfolioService,
    private transactionService: TransactionService,
    private route: ActivatedRoute,
    private router: Router,
    private balanceService: BalanceService/*,
    private stockSplitsService: StockSplitsService*/
  ) { }

  public getOrgSettings(isOranizationType: boolean): Observable<Array<OrganizationMapping | Organization>> {
    return this.http.get<Array<OrganizationMapping | Organization>>(API_URL + this.getOrgEndpoint(isOranizationType))
      .pipe(
        shareReplay(CACHE_SIZE),
        take(1)
      );
  }

  public submitOrgSettings(settings: OrganizationMapping | Organization, isOranizationType: boolean): Observable<HttpResponse<OrganizationMapping | Organization>> {
    return this.http.request<OrganizationMapping | Organization>('PUT', API_URL + this.getOrgEndpoint(isOranizationType), {body: JSON.stringify(settings), headers: {'Content-Type': 'application/json'}, observe: 'response'});
  }

  public deleteOrgMapping(mapping: OrganizationMapping | Organization, isOranizationType: boolean): Observable<HttpResponse<any>> {
    return this.http.request<OrganizationMapping | Organization>('DELETE', API_URL + this.getOrgEndpoint(isOranizationType), {body: JSON.stringify(mapping), headers: {'Content-Type': 'application/json'}, observe: 'response'});
  }

  public getAvailableCustomers(): Observable<Array<Customer>> {
    if (!this.availableCustomersCache) {
      this.availableCustomersCache = this.requestAvailableCustomers()
        .pipe(
          takeUntil(this.availableCustomersCacheRefresh),
          shareReplay(CACHE_SIZE),
          take(1)
        );
    }
    return this.availableCustomersCache;
  }

  // noinspection JSUnusedGlobalSymbols
  public clearAvailableCustomersCache(): void {
    this.availableCustomersCacheRefresh.next();
    this.availableCustomersCache = null;
  }

  public saveCustomer(customer: Customer, saveAsNew: boolean): Observable<HttpResponse<Customer>> {
    const tmpCustomer: Customer = Object.assign(customer);
    if (tmpCustomer.bankAccountSettings && tmpCustomer.bankAccountSettings.length > 0) {
      tmpCustomer.ledgerAccountSettings = tmpCustomer.ledgerAccountSettings.concat(tmpCustomer.bankAccountSettings);
      delete tmpCustomer.bankAccountSettings;
    }
    return this.http.request<Customer>(
      saveAsNew ? 'POST' : 'PUT',
      API_URL + '/customer/settings',
      {body: JSON.stringify(tmpCustomer), headers: {'Content-Type': 'application/json'}, observe: 'response'}
    ).pipe(
      map(httpResponse => httpResponse.clone({body: this.mapCustomerResponse(httpResponse.body)}))
    );
  }

  public getSelectedCustomerId(): number {
    const selectedCustomer = localStorage.getItem('customer');
    return selectedCustomer ? Number(localStorage.getItem('customer')) : null;
  }

  public setSelectedCustomerId(selectedCustomer: number): void {
    const currentSetting = localStorage.getItem('customer');
    if (selectedCustomer) {
      const selectedCustomerStr = selectedCustomer.toString();
      if (currentSetting !== selectedCustomerStr) {
        localStorage.setItem('customer', selectedCustomerStr);
        this.clearAllDataCaches();
      }
    }
    else if (currentSetting) {
      localStorage.removeItem('customer');
      this.clearAllDataCaches();
    }
  }

  public clearSelectedCustomerId(): void {
    this.setSelectedCustomerId(null);
  }

  public clearAllDataCaches(): void {
    this.portfolioService.clearPortfolioCache();
    this.transactionService.clearLedgerCache();
    this.balanceService.clearBalanceCache();
    this.balanceService.clearResultCache();
  }

  public requestCustomerSettings(): Observable<Customer> {
    return this.http.get<Customer>(API_URL + '/customer/settings');
  }

  public getCustomerSettings(): Observable<Customer> {
    if (!this.customerSettingsCache) {
      this.customerSettingsCache = this.requestCustomerSettings()
        .pipe(
          map(customer => this.mapCustomerResponse(customer)),
          takeUntil(this.customerSettingsCacheRefresh),
          shareReplay(CACHE_SIZE),
          take(1)
        );
    }
    return this.customerSettingsCache;
  }

  private mapCustomerResponse(customer: Customer): Customer {
    if (customer.ledgerAccountSettings && customer.ledgerAccountSettings.length > 0) {
      customer.ledgerAccountSettings = customer.ledgerAccountSettings.sort((setting1, setting2) => setting1.ledgerAccount.id < setting2.ledgerAccount.id ? -1 : 1);
      customer.bankAccountSettings = customer.ledgerAccountSettings.filter(setting => setting.broker != null || setting.currency != null);
      customer.ledgerAccountSettings = customer.ledgerAccountSettings.filter(setting => setting.broker == null && setting.currency == null);
    }
    return customer;
  }

  // noinspection JSUnusedGlobalSymbols
  public clearCustomerSettingsCache(): void {
    this.customerSettingsCacheRefresh.next();
    this.customerSettingsCache = null;
  }

  public performExport(): Observable<boolean> {
    return this.http.post(API_URL + '/customer/export', null)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        share()
      );
  }

  private requestAvailableCustomers(): Observable<Array<Customer>> {
    return this.http.get<Array<Customer>>(API_URL + '/user/customers');
  }

  public handleCustomerSelectionChange(selectedId: any): void {
    selectedId = +selectedId;
    if (!selectedId) {  // User selected the default customer
      this.clearSelectedCustomerId();
    }
    else {
      this.setSelectedCustomerId(selectedId);
    }
    if (!this.router.url.startsWith('/settings')) {
      window.location.assign('');  // Navigate to dashboard, discarding current state
    }
    else {
      location.reload();
    }
  }

  public getCustomerYearlyTransactionCount(): Observable<number> {
    return this.http.get<number>(API_URL + '/portfolio/transactions/yearlyCount');
  }
}
