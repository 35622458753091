export class Page<T> {
  pageNumber: number;
  size: number;
  totalElements?: number;
  content?: Array<T>;
  sortField?: string;
  sortOrder = 'asc';

  constructor(size?: number, offset?: number, sortField?: string, sortOrder?: string | number, totalElements?: number, content?: Array<T>) {
    this.content = content;
    this.totalElements = totalElements;
    this.size = size || 20;
    this.pageNumber = (offset || 0) / this.size;
    this.sortField = sortField;
    if (!sortOrder) {
      return;
    }
    if (typeof sortOrder === 'string') {
      this.sortOrder = sortOrder;
    }
    else {
      this.sortOrder = sortOrder === 1 ? 'asc' : 'desc';
    }
  }

  // firstChar: One of '?', '&', ''
  getQueryParams(firstChar: string): string {
    let str = `${firstChar}size=${this.size}&page=${this.pageNumber}`;
    if (this.sortField) {
      str = str + `&sort=${this.sortField},${this.sortOrder}`;
    }
    return str;
  }

  requestArgsEquals(other: Page<T>): boolean {
    if (!other) {
      return false;
    }
    if (this.pageNumber !== other.pageNumber) {
      return false;
    }
    if (this.size !== other.size) {
      return false;
    }
    if (this.sortOrder !== other.sortOrder && this.sortField && other.sortField) {
      return false;
    }
    return this.sortField === other.sortField;
  }
}
