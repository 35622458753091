<!-- Appliy typography and background theme to entire app -->
<section id="parentElement" class="mat-typography">
<!-- Status bar -->
  <div class="status-bar">
<!-- For large displays -->
    <nav class="docs-navbar-header" [ngClass]="{'hide': !(isAuthenticated | async)}">
      <a mat-button class="docs-button" routerLink="/dashboard" aria-label="Secbase">
        <img class="docs-angular-logo" [src]="logoLocation" [alt]="logoAlt"/>
      </a>
      <div class="flex-spacer"></div>
      <p *ngIf="showYearlyCount" class="theme-input" style="margin: 2px 25px 0 0">Transaksjoner siste år: {{yearlyCount}}</p>
      <app-user-dropdown-menu></app-user-dropdown-menu>
      <a mat-stroked-button class="theme-button" (click)="authService.logout()">
        Logg ut
      </a>
    </nav>

<!-- For small displays -->
    <!--<nav class="docs-navbar docs-navbar-show-small">
      <div class="flex-spacer"></div>
      <a mat-stroked-button color="primary"
         class="docs-navbar-show-small docs-button"
         (click)="authService.logout()"
      >Logg ut
      </a>
    </nav>-->
  </div>

<!-- Sidenav -->
  <div class="sidenav">
    <mat-sidenav-container>
      <mat-sidenav id="sidenav-container" [opened]="isAuthenticated | async" mode="side">
        <div class="docs-component-viewer-nav">
          <div class="docs-component-viewer-nav-content">
            <nav>
              <ul id="panel">
                <li *ngFor="let navbarItem of navbarItems">
                  <a [routerLink]="navbarItem.routerLink"
                     routerLinkActive="docs-component-viewer-sidenav-item-selected">
                    {{navbarItem.name}}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </mat-sidenav>
      <mat-sidenav-content id="app-content">
        <div style="width: 10%; height: 10%; background-color: blue"></div>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</section>

