<p-slideMenu
  #accountsMenu
  [popup]="true"
  [model]="menuItems"
  [viewportHeight]="45*menuItems?.length + 35"
  backLabel="Tilbake"
  appendTo="body"
></p-slideMenu>
<button mat-button type="button" (click)="accountsMenu.toggle($event)">
    {{ (selectedCustomer | async)?.name }} <i
  *ngIf="selectedCustomer | async"
  class="fa fa-angle-down"
  style="margin-left: 5px;"
></i>
</button>
