import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { LedgerAccount } from '../domain/ledger-account';
import { Observable, Subject } from 'rxjs';
import { shareReplay, take, takeUntil } from 'rxjs/operators';

const API_ENDPOINT = environment.apiUrl + '/portfolio/accounts/';
const CACHE_SIZE = 1;

@Injectable()
export class BalanceService {
  private balanceCache: Observable<Array<LedgerAccount>>;
  private resultCache: Observable<Array<LedgerAccount>>;
  private balanceCacheRefresh = new Subject<void>();
  private resultCacheRefresh = new Subject<void>();

  constructor(private http: HttpClient) { }

  public getBalance(): Observable<Array<LedgerAccount>> {
    if (!this.balanceCache) {
      this.balanceCache = this.requestBalance()
        .pipe(
          takeUntil(this.balanceCacheRefresh),
          shareReplay(CACHE_SIZE),
          take(1)
        );
    }
    return this.balanceCache;
  }

  // noinspection JSUnusedGlobalSymbols
  public clearBalanceCache(): void {
    this.balanceCacheRefresh.next();  // Causes current cache instance to complete (due to takeUntil)
    this.balanceCache = null;  // Clears the cache
  }

  private requestBalance(): Observable<Array<LedgerAccount>> {
    return this.http.get<Array<LedgerAccount>>(API_ENDPOINT + 'balance');
  }

  public getResult(): Observable<Array<LedgerAccount>> {
    if (!this.resultCache) {
      this.resultCache = this.requestResult()
        .pipe(
          takeUntil(this.resultCacheRefresh),
          shareReplay(CACHE_SIZE),
          take(1)
        );
    }
    return this.resultCache;
  }

  // noinspection JSUnusedGlobalSymbols
  public clearResultCache(): void {
    this.resultCacheRefresh.next();
    this.resultCache = null;
  }

  private requestResult(): Observable<Array<LedgerAccount>> {
    return this.http.get<Array<LedgerAccount>>(API_ENDPOINT + 'results');
  }
}
