<!--suppress JSUnusedLocalSymbols -->
<div
  *ngIf="customer"
  style="margin-top: 20px;
  padding: 10px"
  class="mat-app-background basic-container"
>
  <form [formGroup]="settingsForm" class="form-container settings-form">

<!----------------------------->
<!-- Basic customer settings -->
<!----------------------------->
    <div class="field-group well well-lg">
      <h5 class="title" style="text-align: center; font-size: x-large">Kunde-detaljer</h5>
      <div *ngIf="customer.email && customer.email.length > 0">
        <mat-form-field appearance="fill">
          <mat-label style="font-weight: bold; font-size: large; margin-bottom: 0.5em;">Sluttsedler sendes til</mat-label>
          <input
            matInput
            style="color: black; padding-top: 1em;"
            [value]="customer.email"
            [ngModel]="customer.email"
            [ngModelOptions]="{standalone: true}"
            [attr.disabled]="true"
            placeholder="Epost for sluttsedler"
            onClick="this.select();"
          />
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="standard">
          <mat-label>Selskapsnavn</mat-label>
          <input
            matInput
            formControlName="customerName"
            placeholder="Selskapsnavn AS"
            (click)="$event.target.select()"
          />
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="standard">
          <mat-label>Organisasjonsnummer</mat-label>
          <input
            #orgNumberInput
            matInput
            formControlName="orgNumber"
            placeholder="Ni sifre"
          />
          <mat-hint align="end">{{orgNumberInput.value?.length || 0}}/9</mat-hint>
        </mat-form-field>
      </div>
      <div [ngClass]="{'hidden': hideAccounts}" class="form-row checkbox-group">
        <button mat-button class="theme-button" type="button" (click)="openExemptionSettingsDialog()">
          <mat-icon aria-label="Vis verdipapirinnstillinger">open_in_new</mat-icon>  Vis verdipapir-innstillinger
        </button>
      </div>
      <div [ngClass]="{'hidden': hideOrgButton}" class="form-row checkbox-group">
        <button (click)="openOrganizationDialog()" class="theme-button" mat-button type="button">
          <mat-icon aria-label="Rediger tilknyttede selskaper">open_in_new</mat-icon>  Vis selskaper og koblinger
        </button>
      </div>
      <div [ngClass]="{'hidden': hideAccounts}" class="form-row checkbox-group">
        <mat-checkbox class="theme-input" formControlName="usingCustodialAccount">Bruk depot-bank</mat-checkbox>
        <mat-icon [matTooltip]="CUSTODIAL_ACCOUNT_TOLLTIP" class="option-help">help_outline</mat-icon>
      </div>
      <div class="form-row checkbox-group">
        <mat-checkbox class="theme-input" formControlName="promptForExchangeRates">Deaktiver automatisk utfylling av valutakurs</mat-checkbox>
        <mat-icon [matTooltip]="EXCHANGE_RATES_TOOLTIP" class="option-help">help_outline</mat-icon>
      </div>
    </div>
<!------------------------------------>
<!-- END OF Basic customer settings -->
<!------------------------------------>

<!-------------------------------->
<!-- Ledger accounts formGroup -->
<!-------------------------------->
    <div [ngClass]="{'hidden': hideAccounts}" class="defaultAccountsForm field-group well well-lg" formArrayName="ledgerAccountSettings">
      <h5 class="title" style="text-align: center; font-size: x-large">Konto-oppsett</h5>
      <br>
      <div [hidden]="!showAccountSettings">
<!-- Double ngFor is used to group fields two by two using the inner ngIf="i % 2 === 0/1" -->
        <div *ngFor="let ledgerAccountSetting of ledgerAccountSettings.controls; let i=index" [formGroupName]="i" style="display: contents;">
          <mat-form-field *ngIf="i % 2 === 0">
            <input
              matInput
              type="number"
              formControlName="accountNumber"
              [placeholder]="ledgerAccountSetting.get('shortName').value"
            />
          </mat-form-field>
        </div>
        <div *ngFor="let ledgerAccountSetting of ledgerAccountSettings.controls; let i=index" [formGroupName]="i" style="display: contents;">
          <mat-form-field *ngIf="i % 2 === 1">
            <input
              matInput
              type="number"
              formControlName="accountNumber"
              [placeholder]="ledgerAccountSetting.get('shortName').value"
            />
          </mat-form-field>
        </div>
      </div>
      <button
        class="theme-button"
        mat-stroked-button
        (click)="toggleAccountSettingsForm()"
        type="button"
        style="margin-left: 15px"
      >
        {{ showAccountSettings ? 'Skjul Konto-Oppsett' : 'Rediger Konto-Oppsett' }}
      </button>
    </div>
<!--------------------------------------->
<!-- END OF Ledger accounts formGroup -->
<!--------------------------------------->

<!------------------------------------>
<!-- Integration Settings formGroup -->
<!------------------------------------>
    <div [ngClass]="{'hidden': hideAccounts}" class="field-group well well-lg" formGroupName="integrationSettings">
      <h5 class="title" style="text-align: center; font-size: x-large">Eksport</h5>
      <br>
      <div style="margin-bottom: 2em;">
        <mat-checkbox (change)="handleIntegrationSettingToggle($event.checked)" class="theme-input" #enableExport formControlName="enabled">Aktiver eksport</mat-checkbox>
<!--        <mat-checkbox [hidden]="!enableExport.checked" class="theme-input" style="margin-left: 10em" formControlName="autoExport">Aktiver automatisk daglig eksport</mat-checkbox>-->
      </div>
      <p></p>
      <div [hidden]="!enableExport.checked">
        <div>
          <mat-form-field>
            <mat-select (selectionChange)="handleAccountingSystemSelection($event.value)" #selectedAccountingSystem placeholder="Økonomisystem" formControlName="accountingSystemId">
              <mat-option *ngFor="let accountingSystem of accountingSystems" [value]="accountingSystem.id">
                {{ accountingSystem.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div [hidden]="selectedAccountingSystem.value ? selectedAccountingSystem.value === 2 : customer.integrationSettings?.accountingSystem.id === 2">
            <mat-form-field>
              <input
                matInput
                formControlName="clientKey"
                placeholder="Klient-nøkkel"
              />
            </mat-form-field>
          </div>
        </div>
        <div [hidden]="selectedAccountingSystem.value ? selectedAccountingSystem.value !== 2 : customer.integrationSettings?.accountingSystem.id !== 2">
          <mat-form-field>
            <input
              matInput
              formControlName="username"
              placeholder="Brukernavn"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              formControlName="password"
              placeholder="Passord"
              type="password"
            />
          </mat-form-field>
        </div>
        <span [matTooltip]="!(this.customer.integrationSettings && settingsForm.get('integrationSettings').pristine) ? 'Du må lagre (eller tilbakestille) innstillingene før eksport vil bli tilgjengelig.' : ''">
        <button
          class="theme-button"
          mat-stroked-button
          [disabled]="!(this.customer.integrationSettings && settingsForm.get('integrationSettings').pristine)"
          (click)="exportNow()"
          type="button"
          style="margin-left: 15px"
        >
          Eksporter nå
        </button>
        </span>
      </div>
    </div>
<!------------------------------------------->
<!-- END OF Integration Settings formGroup -->
<!------------------------------------------->

<!----------------------------->
<!-- Bank accounts formArray -->
<!----------------------------->
    <div [ngClass]="{'hidden': hideAccounts}" formArrayName="bankAccountSettings" class="field-group well well-lg">
      <h5 class="title" style="text-align: center; font-size: x-large">Bank-kontoer</h5>
      <div *ngFor="let bankAccountSetting of bankAccountSettings.controls; let i=index" [formGroupName]="i" class="bank-account-form">
        <mat-form-field *ngIf="!settingsForm.value.usingCustodialAccount" appearance="standard">
          <mat-select placeholder="Velg megler" [compareWith]="compareBrokers" formControlName="broker">
            <mat-option
              *ngFor="let broker of brokers | async"
              [value]="broker.id"
              [disabled]="isAlreadySelected(broker.id)"
            >
              {{ broker.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="settingsForm.value.usingCustodialAccount" appearance="standard">
          <mat-select placeholder="Velg valuta" formControlName="currency">
            <mat-option
              *ngFor="let currency of currencies"
              [value]="currency"
              [disabled]="isAlreadySelected(currency)"
            >
              {{ currency }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            type="number"
            formControlName="accountNumber"
            placeholder="Kontonummer"
          />
        </mat-form-field>
      </div>
      <button
        class="theme-button"
        mat-stroked-button
        (click)="addBankAccountSetting()"
        type="button"
        style="margin-left: 15px"
      >
        Legg til konto
      </button>
    </div>
<!------------------------------------>
<!-- END OF Bank accounts formArray -->
<!------------------------------------>

    <div style="display: flex; align-content: center; justify-content: center;">
      <button
        class="theme-submit"
        mat-button
        (click)="onSubmit()"
        [disabled]="settingsForm.pristine || settingsForm.invalid"
        style="margin: 5px 5px 20px 5px"
      >
        {{ submitAsNewCustomer ? 'Bekreft registrering' : 'Lagre endringer' }}
      </button>
      <button
        mat-button
        (click)="revertUnsavedChanges()"
        [disabled]="settingsForm.pristine"
        class="theme-button"
        style="margin: 5px 5px 20px 5px"
      >
        Tilbakestill endringer
      </button>
    </div>
  </form>
</div>
<!--<p>{{settingsForm.value | json}}</p>-->
