import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PortfolioItem } from '../../domain/portfolio-item';
import { SecurityTypes } from '../../domain/security.types';

@Component({
  selector: 'add-instrument-dialog',
  templateUrl: 'add-instrument-dialog.component.html',
  styleUrls: ['add-instrument-dialog.component.scss']
})
export class AddInstrumentDialogComponent {
  public newPortfolioItem = new PortfolioItem();
  public securityTypeDict = new SecurityTypes();
  public securityTypes: Array<String> = Object.keys(this.securityTypeDict);

  constructor(private dialogRef: MatDialogRef<AddInstrumentDialogComponent>) { }

  submitNewInstrument() {
    this.dialogRef.close(this.newPortfolioItem);
  }

  onExemptionChange(value) {
    this.newPortfolioItem.exemptionMethod = value;
  }
}
