<div *ngIf="data.postSuccess; else postError">
  <h5
    mat-dialog-title
    class="title"
    style="text-align: center; font-size: x-large"
  >
    {{ data.newCustomer ? 'Ny bruker opprettet' : 'Endringer lagret' }}
  </h5>
  <mat-dialog-content *ngIf="data.newCustomer" style="display: flex; flex-direction: column; align-items: center; margin-bottom: 5px;">
    <div style="align-self: stretch;">
      <h5 class="text-area">
        Registrer sluttsedler ved å sende dem til følgende epost:
      </h5>
    </div>
    <div>
      <mat-form-field appearance="fill" style="width: 17em">
        <mat-label style="font-weight: bold; font-size: large; margin-bottom: 0.5em;">Epost</mat-label>
        <input
          matInput
          type="email"
          spellcheck="false"
          style="color: black; padding-top: 1em;"
          [value]="data.customer.email"
          [ngModel]="data.customer.email"
          [ngModelOptions]="{standalone: true}"
          [attr.disabled]="true"
          placeholder="Epost for sluttsedler"
          onClick="this.select();"
        />
      </mat-form-field>
    </div>
  </mat-dialog-content>
</div>
<ng-template #postError>
  <h1 mat-dialog-title class="title" style="text-align: center; color: red">
    {{ data.newCustomer ? 'Feil ved registrering av bruker' : 'Feil ved lagring av instillinger' }}
  </h1>
  <mat-dialog-content *ngIf="data.error" style="display: flex; flex-direction: column; align-items: center; margin-bottom: 5px;">
    <div style="align-self: stretch;">
      <h5 class="text-area">
        Error {{data.error}}
      </h5>
    </div>
  </mat-dialog-content>
</ng-template>
<mat-dialog-actions style="justify-content: center;">
  <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>
