import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';

import {environment} from '../environments/environment';

@Injectable()
export class HttpRedirectService implements CanActivate {

  canActivate(): Observable<boolean> {
    if (environment.production && location.protocol !== 'https:') {
      location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
      return of(false);
    }
    return of(true);
  }
}
