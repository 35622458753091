import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

import { Observable, of, Subscription } from 'rxjs';
import { filter, pairwise, shareReplay, startWith } from 'rxjs/operators';
import { AccountsService } from './accounts/accounts.service';

import { AuthService } from './authentication/auth.service';
import { GlobalsService } from './globals.service';

export interface NavbarItems {
  name: string;
  routerLink: string;
}

const navbarItems: Array<NavbarItems> = [
  {
    name: 'Forside',
    routerLink: '/dashboard'
  },
  {
    name: 'Ny Transaksjon',
    routerLink: '/transactions/add'
  },
  {
    name: 'Vis Regnskapsposter',
    routerLink: '/ledger'
  }
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  public isAuthenticated = new Observable<boolean>();
  public logoLocation;
  public logoAlt = 'Secbase';
  public navbarItems = navbarItems;
  public routeSubscription: Subscription;
  public referrer: string;
  public showYearlyCount = false;
  public yearlyCount: number;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private router: Router,
    public globalsService: GlobalsService,
    public authService: AuthService,
    public accountsService: AccountsService
  ) {
    authService.handleAuthentication();
    authService.scheduleRenewal();
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.isAuthenticated = of(true);
    }
    else {
      this.isAuthenticated = this.authService.authResult
        .pipe(
          startWith(false),
          shareReplay()
        );
    }
    // Set favicon and logo based on hostname
    let themeName: string;
    if (this.globalsService.bookkeeperMode) {
      this.logoAlt = 'Bookkeeper';
      themeName = "bookkeeper";
    }
    else if (this.globalsService.escaliMode) {
      themeName = "escali";
      this.logoAlt = "Stacc";
      this.isAuthenticated.subscribe(val => {
        if (val) {
          this.accountsService.getCustomerYearlyTransactionCount().subscribe(res => {
            this.showYearlyCount = true;
            this.yearlyCount = res;
          });
        }
      });
    }
    this._document.getElementById('parentElement').classList.add(themeName || 'secbase');
    const themeSuffix = themeName != null ? '-' + themeName : '';
    this.logoLocation = `../../assets/logo${themeSuffix}.png`;
    this._document.getElementById('appFavicon').setAttribute('href', `/assets/favicon${themeSuffix}.png`);

    // noinspection SuspiciousInstanceOfGuard
    this.routeSubscription = this.router.events
      .pipe(
        filter(e => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe(
        (e: Array<any>) => {
          this.referrer = e[0].urlAfterRedirects;
        },
        (error) => console.log('route error: ', error)
      );
  }

  ngOnDestroy(): void { this.routeSubscription.unsubscribe(); }

}
