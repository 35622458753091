<h5 mat-dialog-title class="title" style="text-align: center; font-size: x-large">LEGG TIL VERDIPAPIR</h5>
<mat-dialog-content>
  <form #newInstrumentForm="ngForm">
    <div class="form-row">
      <mat-form-field appearance="standard">
        <mat-select name="security.securityType" required placeholder="Verdipapir type" [(ngModel)]="newPortfolioItem.security.securityType">
          <mat-option *ngFor="let securityType of securityTypes" [value]="securityTypeDict[securityType]">
            {{ securityType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="standard">
        <mat-label>Navn</mat-label>
        <input matInput required
          name="security.name"
          [(ngModel)]="newPortfolioItem.security.name"
          placeholder="Selskap AS"
          (click)="$event.target.select()"
        />
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="standard">
        <mat-label>Ticker</mat-label>
        <input matInput
          name="security.ticker"
          [(ngModel)]="newPortfolioItem.security.ticker"
          placeholder="Ticker"
          (click)="$event.target.select()"
        />
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="standard">
        <mat-label>ISIN</mat-label>
        <input matInput
          name="security.isin"
          [(ngModel)]="newPortfolioItem.security.isin"
          placeholder="NO9999999999"
          (click)="$event.target.select()"
        />
      </mat-form-field>
    </div>
    <div id="toggle-group" class="form-row">
      <mat-label>Fritak</mat-label>
      <mat-button-toggle-group name="exemptionMethod" class="theme-toggle" [(ngModel)]="newPortfolioItem.exemptionMethod">
        <mat-button-toggle [ngClass]="{'mat-button-toggle-checked': newPortfolioItem.exemptionMethod}" [value]="true">Ja</mat-button-toggle>
        <mat-button-toggle [ngClass]="{'mat-button-toggle-checked': !newPortfolioItem.exemptionMethod}" [value]="false">Nei</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: center;">
  <button class="theme-submit" mat-button type="button" (click)="submitNewInstrument()" [disabled]="newInstrumentForm.form.invalid">Lagre</button>
  <button class="theme-button" mat-button type="button" mat-dialog-close>Avbryt</button>
</mat-dialog-actions>
