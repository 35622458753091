import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, startWith, switchMap, tap } from 'rxjs/operators';
import { AbstractTableComponent } from './abstract-table.component';
import { DisplayMode } from './domain/display-mode';
import { Filter } from './domain/filter';
import { LedgerEntry } from './domain/ledger-entry';
import { Page } from './domain/page';
import { RequestArguments } from './domain/request-arguments';
import { TableColumn } from './domain/table-column';
import { TransactionService } from './transactions/transaction.service';

export interface FilterValues {
  transactionId?: number;
  global?: string;
  fromDate?: Date;
  toDate?: Date;
}

@Component({
  selector: 'my-ledger',
  templateUrl: './ledger-entries.component.html',
  styleUrls: ['./abstract-table.component.scss']
})

export class LedgerEntriesComponent extends AbstractTableComponent<LedgerEntry> implements OnInit {
  public displayModeAll = DisplayMode.ALL;
  public templateValues = {global: '', fromDate: undefined as Date, toDate: undefined as Date};
  public tableColumns: Array<TableColumn> = [
    {header: 'Konto', field: 'ledgerAccountSetting.ledgerAccount.shortName'},
    {header: 'Kontonummer', field: 'ledgerAccountSetting.accountNumber'},
    {header: 'Handelsdato', field: 'voucherDate', type: 'date'},
    {header: 'Debet', field: 'debitAmount', type: 'decimal'},
    {header: 'Kredit', field: 'creditAmount', type: 'decimal'},
    {header: 'Bilagsnummer', field: 'id', type: 'integer'}
  ];
  public rowsPerPage = 20;
  public showPaginator = false;
  public totalRecords: number;
  public hasError = false;
  public isComplete = false;
  public filterValues: FilterValues = {};
  public filterSubject = new Subject<Array<Filter>>();
  public defaultPage = new Page<LedgerEntry>(20, 0, 'id', 'desc');
  public pageSubject = new BehaviorSubject<Page<LedgerEntry>>(this.defaultPage);

  constructor(private transactionService: TransactionService, private titleService: Title, public dialog: MatDialog) {
    super(dialog);
    this.titleService.setTitle("Regnskapstransaksjoner");
  }

  @Input() displayMode = DisplayMode.ALL;
  @Input() ledgerEntries: Array<LedgerEntry> = [];
  @Input() transactionId?: number;

  ngOnInit(): void {
    if (this.displayMode === DisplayMode.TRANSACTION) {
      if (!this.transactionId) {
        this.isComplete = true;
        this.hasError = true;
        console.log("Error: ledgerEntries input or transactionId is required for DisplayMode.TRANSACTION.");
        return;
      }
      this.filterValues.transactionId = this.transactionId;
    }
    else if (this.displayMode !== DisplayMode.ALL) {
      this.isComplete = true;
      this.hasError = true;
      console.log("Error: DisplayMode must be one of ALL, TRANSACTION.");
      return;
    }
    else {
      this.tableColumns.splice(0, 0, {
        header: 'Verdipapir',
        field: 'transaction.portfolioItem.security.name',
        type: 'assetLink'
      });
      this.tableColumns.push({header: 'Detaljer', field: 'details', type: 'detailsButton'});
    }

    combineLatest([
      this.pageSubject,
      this.filterSubject
        .pipe(
          startWith(this.updateFilterValues()),
          debounceTime(400),
          // Creating two RequestArgument objects here only to use their argumentEquals method to check for equality.
          distinctUntilChanged((x: Array<Filter>, y: Array<Filter>) =>
            new RequestArguments(undefined, x).argumentsEquals(new RequestArguments(undefined, y))
          )
      )
    ])
      .pipe(
        // Skip the first get with initial page and filter values if this.ledgerEntries isn't empty
        skip(this.ledgerEntries && this.ledgerEntries.length > 0 ? 1 : 0),
        tap(() => {
          this.isComplete = false;
          this.hasError = false;
        }),
        map(([page, filters]) => new RequestArguments(page, filters)),
        switchMap(args => this.transactionService.getLedger(args))
      ).subscribe(
      ledgerPage => {
        this.totalRecords = ledgerPage.totalElements;
        this.showPaginator = this.showPaginator || this.totalRecords > this.rowsPerPage;  // Prevents hiding of paginator after changing rowsPerPage
        this.ledgerEntries = ledgerPage.content;
        this.hasError = false;
        this.isComplete = true;
      },
      error => {
        console.log("Error getting ledger:");
        console.log(error);
        this.ledgerEntries = [];
        this.hasError = true;
        this.isComplete = true;
      }
    );

    // Underlying data has changed => reload data from service
    this.transactionService.ledgerChangedNotifier
      .subscribe(() => this.lazyLoadLedgerEntries({}));
  }

  lazyLoadLedgerEntries(event: LazyLoadEvent) {
    this.rowsPerPage = event.rows;
    this.updateFilterValues({global: event.globalFilter});

    let field = event.sortField;
    if (field === 'creditAmount' || field === 'debitAmount') {
      field = 'amount';
    }
    // This wierd check is due to event.sortOrder being set to 1/asc even though user hasn't overridden initial sort
    const order = field ? event.sortOrder : 'desc';

    this.pageSubject.next(new Page(event.rows, event.first, field || this.defaultPage.sortField, order));
  }

  updateFilterValues(newValues: FilterValues = {}): Array<Filter> {
    const dateArgs = {
      fromDate: undefined as string,
      toDate: undefined as string
    };
    ['fromDate', 'toDate'].forEach(key => {
      // Note that previous fromDate/toDate values are cleared if the new value === null
      if (newValues[key] !== undefined) {
        this.templateValues[key] = newValues[key];
        this.filterValues[key] = newValues[key];
        dateArgs[key] = newValues[key] ? this.parseDate(newValues[key]) : null;
      }
      else {
        dateArgs[key] = this.parseDate(this.filterValues[key]);
      }
    });
    if (newValues.global !== undefined) {
      this.filterValues.global = newValues.global;
    }

    // Since we're looking to filter voucherDate by from, to or between, we always need to pass two voucherDate filters,
    // otherwise the API would interpret the filter as voucherDate = ...
    if (dateArgs.fromDate && !dateArgs.toDate) {
      dateArgs.toDate = new Date(new Date().getFullYear() + 10, 1, 1).toISOString().replace(/T.+/, '');
    }
    else if (dateArgs.toDate && !dateArgs.fromDate) {
      dateArgs.fromDate = new Date(new Date().getFullYear() - 50, 1, 1).toISOString().replace(/T.+/, '');
    }

    const newFilters = [
      new Filter('global', undefined, this.filterValues.global),
      new Filter('transaction.id', undefined, this.filterValues.transactionId),
      new Filter('voucherDate', undefined, dateArgs.fromDate),
      new Filter('voucherDate', undefined, dateArgs.toDate)
    ];
    this.filterSubject.next(newFilters);
    return newFilters;
  }

  public parseDate(date: Date): string {
    if (!date) {
      return null;
    }
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().replace(/T.+/, '');
  }

  // Using this function since the same is not possible in the template
  public createFilterObject(key, value) {
    return {[key]: value};
  }

  exportLedgerToExcel() {
    // Huge page used in order to DL all relevant transactions in one go
    const exportColumns = this.tableColumns.filter(col => col.type !== "detailsButton");  // Don't export the empty details column
    exportColumns.splice(0, 0, {header : 'Transaksjon ID', field : 'transaction.id'});
    exportColumns.splice(2, 0, {header : 'ISIN', field : 'transaction.portfolioItem.security.isin'});
    const currentPage = this.pageSubject.getValue();
    const hugePage = new Page<LedgerEntry>(9999999, 0, currentPage.sortField, currentPage.sortOrder);
    this.exportDataToExcel(this.transactionService.getLedger(new RequestArguments(hugePage, this.updateFilterValues())), "regnskapstransaksjoner", exportColumns);
  }
}
