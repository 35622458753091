import { Broker } from './broker';
import { Organization } from './customer';
import { LedgerEntry } from './ledger-entry';
import { Note } from "./note";
import { Security } from './security';

export class Transaction {
  id: number;
  transactionDate: Date | string;
  transactionEndTime: Date | string;
  settlementDate: Date | string;
  registeredDate: Date | string;
  interestProfits: number | string;
  transactionStatus: string;
  transactionType: string;
  portfolioItem: {
    security: Security;
  };
  units: number | string;
  pricePerUnit: number | string;
  fees: number | string;
  amount: number | string;
  amountNOK: number | string;
  settlementAmount: number | string;
  note: Note;
  broker: Broker;
  organization: Organization;
  ticker: string;
  transactionCurrency: string;
  settlementCurrency: string;
  exchangeRate: number | string;
  exemptionMethod: boolean;
  ledgerEntries: Array<LedgerEntry>;
  recipient: string;

  constructor() {
    this.note = new Note();
    this.broker = new Broker();
    this.portfolioItem = {security: new Security()};
  }
}
