import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, share, shareReplay, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AbstractService } from '../abstract.service';
import { LedgerEntry } from '../domain/ledger-entry';

import { Page } from "../domain/page";
import { RequestArguments } from '../domain/request-arguments';
import { Transaction } from '../domain/transaction';

const API_URL =  environment.apiUrl + '/portfolio';
const CACHE_SIZE = 1;

export class PendingRequest {
  path: string;
  method: string;
  options: any;
  subscription: Subject<any>;

  constructor(path: string, method: string, options: any, subscription: Subject<any>) {
    this.path = path;
    this.method = method;
    this.options = options;
    this.subscription = subscription;
  }
}

@Injectable()
export class TransactionService extends AbstractService {
  private requests$ = new Subject<any>();
  private queue: PendingRequest[] = [];
  private prevLedgerArgs: RequestArguments<LedgerEntry>;
  private ledgerCache: Observable<Page<LedgerEntry>>;
  public ledgerCacheRefresh = new Subject<void>();
  public transactionsChangedNotifier = new Subject<void>();
  public ledgerChangedNotifier = new Subject<void>();

  constructor(private http: HttpClient) {
    super();
    this.requests$.subscribe(request => this.execute(request));
  }

  // Uses a request queue rather than the normal cache setup due to needing to handle multiple simultaneous requests
  public getTransactions(requestArgs: RequestArguments<Transaction>): Observable<Page<Transaction>> {
    return this.addRequestToQueue(`/transactions${requestArgs.getQueryParams('?')}`, 'GET', null);
  }

  public getSingleTransaction(id: number): Observable<Transaction> {
    return this.http.get<Transaction>(API_URL + '/transactions/' + id)
      .pipe(
        take(1),
        mergeMap(transaction => {
          transaction.ledgerEntries = this.parseLedgerEntries(transaction.ledgerEntries);
          return this.getRawNote(transaction.id, transaction.note.contentType).pipe(
            map(note => {
              transaction.note.content = note;
              this.parseTransactionDates(transaction);
              return transaction;
            })
          );
        })
      );
  }

  public parseTransactionDates(transaction: Transaction) {
    ['transactionDate', 'transactionEndTime', 'settlementDate', 'registeredDate'].forEach(key => {
      if (transaction[key]) {
        transaction[key] = new Date(transaction[key]);
      }
    });
  }

  public getRawNote(id: number, contentType: Transaction['note']['contentType']): Observable<Blob | string> {
    const responseType = contentType.type === 'application' && contentType.subtype === 'pdf' ? 'arraybuffer' : 'text';
    // @ts-ignore
    return this.http.get(API_URL + '/transactions/' + id + '/note', {responseType: responseType})
      .pipe(
        map(response => responseType === 'arraybuffer' ? new Blob([response as ArrayBuffer], {type: 'application/pdf'}) : response)
      );
  }

  public getLedger(requestArgs: RequestArguments<LedgerEntry>): Observable<Page<LedgerEntry>> {
    if (!requestArgs.argumentsEquals(this.prevLedgerArgs)) {
      this.prevLedgerArgs = requestArgs;
      this.clearLedgerCache();
    }
    if (!this.ledgerCache) {  // GET from API if ledgerCache is empty
      this.ledgerCache = this.requestPage<LedgerEntry>(`/ledger${requestArgs.getQueryParams('?')}`)
        .pipe(
          map(ledgerPage => {
            ledgerPage.content = this.parseLedgerEntries(ledgerPage.content);
            return ledgerPage;
          }),
          takeUntil(this.ledgerCacheRefresh),
          shareReplay(CACHE_SIZE),
          take(1)
       );
    }
    return this.ledgerCache;
  }

  public parseLedgerEntries(ledgerEntries: Array<LedgerEntry>): Array<LedgerEntry> {
    if (!ledgerEntries || ledgerEntries.length === 0) {
      return null;
    }
    ledgerEntries.map(ledgerEntry => {
      ledgerEntry[(ledgerEntry.amount > 0 ? 'debit' : 'credit') + 'Amount'] = ledgerEntry.amount;
      return ledgerEntry;
    });
    return ledgerEntries;
  }

  public clearLedgerCache(): void {
    this.ledgerCacheRefresh.next();
    this.ledgerCache = null;
  }

  private requestPage<T>(requestPath: string): Observable<Page<T>> {
    return this.http.get<Page<T>>(API_URL + requestPath);
  }

  private execute<T>(requestData: PendingRequest) {
    this.http.get<Page<T>>(API_URL + requestData.path)
      .subscribe(res => {
        const sub = requestData.subscription;
        sub.next(res);
        this.queue.shift();
        this.startNextRequest();

      });
  }

  private addRequestToQueue(url, method, options) {
    const sub = new Subject<any>();
    const request = new PendingRequest(url, method, options, sub);

    this.queue.push(request);
    if (this.queue.length === 1) {
      this.startNextRequest();
    }

    return sub;
  }

  private startNextRequest() {
    // get next request, if any.
    if (this.queue.length > 0) {
      this.execute(this.queue[0]);
    }
  }

  public saveTransaction(unparsedTransaction: Transaction, modifyExisting = false): Observable<Transaction> {
    return this.http.request<Transaction>(modifyExisting ? "PUT" : "POST", API_URL + "/transactions", {body: this.getTransactionFormData(unparsedTransaction)})
      .pipe(share());
  }

  public getTransactionFormData(unparsedTransaction: Transaction): FormData {
    const copy: Transaction = JSON.parse(JSON.stringify(unparsedTransaction));  // Deep copy...

    copy.transactionDate = this.parseDate(copy.transactionDate as Date);
    copy.settlementDate = this.parseDate(copy.settlementDate as Date);
    delete copy.registeredDate;

    // Overwrite note contentType to type string
    if (copy?.note?.contentType?.type && copy?.note?.contentType?.subtype){
      copy.note.contentType = (copy.note.contentType.type + '/' + copy.note.contentType.subtype) as any;
    }

    // Convert all numeric attributes from strings to numbers
    ['interestProfits', 'units', 'pricePerUnit', 'fees', 'amount', 'settlementAmount', 'amountNOK', 'exchangeRate'].forEach(key => {
      if (copy[key] != null && typeof copy[key] === 'string') {
        copy[key] = +(String(copy[key]).replace(',', '.'));
      }
    });

    const formData = this.objectToFormData(copy);
    if (unparsedTransaction.note.contentType?.type === 'application' && unparsedTransaction.note.contentType?.subtype === 'pdf') {
      formData.append('file', unparsedTransaction.note.content as Blob);
    }
    return formData;
  }

  // Get string from date, adjusting for timezone. Without the timezone adjustment, 2000-01-01T00:00:00 GMT+1 becomes 1999-12-31T22:00
  public parseDate(date: Date | string): string {
    if (!date) {
      return null;
    }
    let dateStr;
    if (typeof date === 'string') {
      dateStr = date;
    }
    else {
      dateStr = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    }
    return dateStr.replace(/T.+/, '');
  }

  public deleteTransaction(id: number): Observable<boolean> {
    return this.http.delete<void>(`${API_URL}/transactions/${id}`)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  public postNote(note: File): Observable<Array<Transaction>> {
    const formData = new FormData();
    formData.append('note', note);
    return this.http.post<Array<Transaction>>(API_URL + '/transactions/note', formData)
      .pipe(
        share()
      );
  }
}
