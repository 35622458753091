import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of, timer, ReplaySubject } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as auth0 from 'auth0-js';

const RETRY_DELAY = 2000;
(window as any).global = window;

@Injectable()
export class AuthService {
  public authResult = new ReplaySubject<boolean>(1);
  public refreshSubscription: any;
  public token: string;
  public auth0 = new auth0.WebAuth({
    clientID: environment.auth0.clientID,
    domain: environment.auth0.domain,
    responseType: 'token id_token',
    audience: environment.auth0.audience,
    redirectUri: document.location.origin + '/',
    scope: 'openid profile'
  });

  constructor(private router: Router) {
    // set token if saved in local storage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.token = currentUser && currentUser.token;
  }

  public login(): void {
    localStorage.removeItem('customer');
    this.auth0.authorize();
  }

  public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.setSession(authResult);
        this.authResult.next(true);
        if (authResult.idTokenPayload[environment.auth0.customClaimsNamespace + 'first_login']) {
          this.router.navigate(['/settings'], {queryParams: {event: 'first-login'}});
        }
        else {
          this.router.navigate(['/dashboard']);
        }
      }
      else {
        this.authResult.next(false);
        if (err) {
          console.log('Error logging in:');
          console.log(err);
        }
      }
    });
  }


  public scheduleRenewal() {
    if (!this.isAuthenticated()) {
      return;
    }
    this.unscheduleRenewal();
    const expiresAt = JSON.parse(window.localStorage.getItem('expires_at'));

    const source = of(expiresAt)
      .pipe(
        flatMap(expirationTime => {
          return timer(Math.max(1, expirationTime - Date.now()));  // Use the delay in a timer to run the refresh at the proper time
        })
      );
    // Once the delay time from above is reached, get a new JWT and schedule additional refreshes
    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
      this.scheduleRenewal();
    });
  }

  public unscheduleRenewal() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  public renewToken(retry = true) {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log('Error when renewing token:');
        console.log(err);
        if (retry) {  // Retry once if failed
          setTimeout(() => {
            this.renewToken(false);
          }, RETRY_DELAY);
        }
      }
      else {
        this.setSession(result);  // Renew of token successful
      }
    });
  }

  private setSession(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('scope', authResult.scope);
    localStorage.setItem('expires_at', expiresAt);
    this.scheduleRenewal();
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('scope');
    localStorage.removeItem('customer');
    this.unscheduleRenewal();
    this.router.navigate(['/login']);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    return new Date().getTime() < expiresAt;
  }

  public getGrantedScopes(): string {
    return localStorage.getItem('scope');
  }
}
