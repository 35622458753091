export class SecurityTypes {
  readonly 'Aksje';
  readonly 'Unotert aksje';
  readonly 'Opsjon';
  readonly 'Obligasjon';
  readonly 'Aksjefond';
  readonly 'Obligasjonsfond';

  constructor() {
    this['Aksje'] = 'STOCK';
    this['Unotert aksje'] = 'UNLISTED_STOCK';
    this['Opsjon'] = 'OPTION';
    this['Obligasjon'] = 'BOND';
    this['Aksjefond'] = 'STOCK_FUND';
    this['Obligasjonsfond'] = 'BOND_FUND';
  }
}
