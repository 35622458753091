import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { map } from 'rxjs/operators';
import { CustomerSettingsComponent } from './accounts/customer-settings.component';
import { AssetDetailsComponent } from './asset-details.component';
import { AuthGuardService } from './authentication/auth-guard.service';
import { LoginComponent } from './authentication/login.component';

import { DashboardComponent } from './dashboard.component';
import { HttpRedirectService } from './http-redirect.service';
import { LedgerEntriesComponent } from './ledger-entries.component';
import { AddTransactionComponent } from './transactions/add-transaction/add-transaction.component';

// ChainedGuard is used to ensure that the potential Http redirect happens before checking auth status and potentially authentication
@Injectable()
export class ChainedGuard {
  constructor(private redirectGuard: HttpRedirectService, private authGuard: AuthGuardService) { }

  // noinspection JSUnusedGlobalSymbols
  canActivate() {
    return this.redirectGuard.canActivate()
      .pipe(
        map(bool => bool ? this.authGuard.canActivate() : false)
      );
  }
}

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [ChainedGuard] },
  { path: 'dashboard',  component: DashboardComponent, canActivate: [ChainedGuard] },
  { path: 'details/:isin',  component: AssetDetailsComponent, canActivate: [ChainedGuard] },
  { path: 'transactions/add/:displayAssetDialog/:newTransaction',  component: AddTransactionComponent, canActivate: [ChainedGuard] },
  { path: 'transactions/add/:displayAssetDialog',  component: AddTransactionComponent, canActivate: [ChainedGuard] },
  { path: 'transactions/add',  component: AddTransactionComponent, canActivate: [ChainedGuard] },
  { path: 'ledger', component: LedgerEntriesComponent, canActivate: [ChainedGuard] },
  { path: 'settings', component: CustomerSettingsComponent, canActivate: [ChainedGuard] },
  { path: 'login', component: LoginComponent, canActivate: [HttpRedirectService] },
  { path: '**', redirectTo: 'dashboard', canActivate: [HttpRedirectService] }
];

@NgModule({
            imports: [
              CommonModule,
              RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
            providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
            exports: [ RouterModule ]
          })

export class AppRoutingModule { }
