import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SortEvent } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { AbstractTableComponent } from '../abstract-table.component';
import { Broker } from '../domain/broker';
import { CompareById, Organization, OrganizationMapping } from '../domain/customer';
import { PortfolioItem } from '../domain/portfolio-item';
import { TableColumn } from '../domain/table-column';
import { CellEditErrorDialogComponent } from '../portfolio/portfolio.component';
import { PortfolioService } from '../portfolio/portfolio.service';
import { AccountsService } from './accounts.service';

export interface CellEditCompleteEvent {
  field: string;
  data: any;
  originalEvent: Event;
}
@Component({
  selector: 'organization-settings',
  templateUrl: 'organization-settings.component.html',
  styleUrls: ['../abstract-table.component.scss', './organization-settings.component.scss']
})
export class OrganizationSettingsComponent extends AbstractTableComponent<PortfolioItem> implements OnInit {
  public organizationMode = true;
  public editingRowIndex: number;
  public totalRecords: number;
  public rowsPerPage = 17;
  public mappings: Array<OrganizationMapping> = [];
  public organizations: Array<Organization> = [];
  public tableData: Array<OrganizationMapping | Organization> = [];
  public brokers: Array<Broker> = [];
  public hasError = false;
  public isComplete = false;
  public mappingColumns: Array<TableColumn> = [
    {header: 'Selskap', field: 'organization', placeholder: 'Velg selskap'},
    {header: 'Megler', field: 'broker', placeholder: 'Velg megler'},
    {header: 'Identifikator', field: 'identifier', placeholder: 'Identifikator', type: 'text'},
    {header: 'Slett', field: 'deleteButton'}
  ];
  public organizationColumns: Array<TableColumn> = [
    {header: 'Navn', field: 'name', placeholder: 'Selskapsnavn', type: 'text'},
    {header: 'Organisasjonsnummer', field: 'organizationNumber', placeholder: 'Organisasjonsnummer', type: 'text'},
    {header: 'Epost', field: 'noteToAddress', placeholder: 'organisasjon@sluttsedler.ecali.net', type: 'text'},
    {header: 'Slett', field: 'deleteButton'}
  ];
  public tableColumns: Array<TableColumn> = this.organizationMode ? this.organizationColumns : this.mappingColumns;
  private prevSortField: string;
  public getFilterFields = () => this.organizationMode ? ['name', 'organizationNumber'] : ['organization.name', 'broker.name', 'identifier'];

  constructor(private accountsService: AccountsService, private portfolioService: PortfolioService, public dialog: MatDialog) {
    super(dialog);
  }

  ngOnInit() {
    this.getOrgMappings();
    this.getOrganizations();
    this.portfolioService.getBrokers()
      .subscribe(
        brokerResponse => {
          this.brokers = brokerResponse;
        },
        error => {
          console.log("Error getting brokers:");
          console.log(error);
          this.brokers = [];
        }
      );
  }

  toggleOrganizationMode() {
    if (!this.organizationMode) {
      this.tableData = this.organizations;
      this.totalRecords = this.mappings.length;
      this.getOrganizations();
      this.tableColumns = this.organizationColumns;
    }
    else {
      this.tableData = this.mappings;
      this.totalRecords = this.organizations.length;
      this.getOrgMappings();
      this.tableColumns = this.mappingColumns;
    }
    this.hasError = false;
    this.isComplete = false;
    this.prevSortField = void 0;
    this.editingRowIndex = void 0;
    this.organizationMode = !this.organizationMode;
  }

  getOrgMappings() {
    this.accountsService.getOrgSettings(false)
    .pipe(
      tap(() => {
        this.isComplete = false;
        this.hasError = false;
      }))
    .subscribe(
      mappingResponse => {
        this.mappings = <Array<OrganizationMapping>>mappingResponse;
        if (!this.organizationMode) {
          this.totalRecords = mappingResponse.length;
          this.tableData = this.mappings;
        }
        this.hasError = false;
        this.isComplete = true;
      },
      error => {
        console.log("Error getting orgMappings:");
        console.log(error);
        this.mappings = [];
        if (!this.organizationMode) {
          this.tableData = [];
          this.hasError = true;
          this.isComplete = true;
          this.totalRecords = null;
        }
      }
    );
  }

  getOrganizations() {
    this.accountsService.getOrgSettings(true)
      .subscribe(
        orgResponse => {
          this.organizations = <Array<Organization>>orgResponse;
          if (this.organizationMode) {
            this.totalRecords = orgResponse.length;
            this.tableData = this.organizations;
          }
        },
        error => {
          console.log("Error getting organizations:");
          console.log(error);
          this.organizations = [];
          if (this.organizationMode) {
            this.tableData = [];
            this.hasError = true;
            this.isComplete = true;
            this.totalRecords = null;
          }
        }
      );
  }

  handleEditComplete(event: CellEditCompleteEvent) {
    if (this.isValid(event['data'])) {
      this.accountsService.submitOrgSettings(event['data'], this.organizationMode)
        .subscribe(
          res => {
            if (!res) {
              this.dialog.open(CellEditErrorDialogComponent);
            }
            else if (this.editingRowIndex != null && this.tableData[this.editingRowIndex].id == null) {
              this.tableData[this.editingRowIndex].id = res.body.id;
            }
            this.editingRowIndex = null;
          },
          () => this.dialog.open(CellEditErrorDialogComponent)
        );
    }
  }

  handleDelete(rowData: any) {
    this.editingRowIndex = null;
    this.accountsService.deleteOrgMapping(rowData, this.organizationMode)
      .subscribe(
        res => {
          if (!res) {
            this.dialog.open(CellEditErrorDialogComponent);
          }
          else {
            this.organizationMode ? this.getOrganizations() : this.getOrgMappings();
          }
        },
        () => this.dialog.open(CellEditErrorDialogComponent)
      );
  }

  isValid(rowData: OrganizationMapping | Organization): boolean {
    const obj = this.organizationMode ? new Organization(rowData) : new OrganizationMapping(rowData);
    return obj && obj.isValid();
  }

  compareById(f1: CompareById, f2: CompareById) {
    return f1 && f2 && f1.id === f2.id;
  }

  getOptions(field: string) {
    return field === 'organization' ? this.organizations : this.brokers;
  }

  getCellData(rowData: any, field: string): any {
    if (field === 'organization' || field === 'broker') {
      return rowData[field]['name'];
    }
    return rowData[field];
  }

  getSortKey(field): string {
    if (['organization', 'broker'].includes(field)) {
      return field + '.name';
    }
    return field;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const innerSort = (usePrevSortField: boolean): number => {
          let value1 = usePrevSortField && this.prevSortField != null ? data1[this.prevSortField] : data1[event.field['field']];
          let value2 = usePrevSortField && this.prevSortField != null ? data2[this.prevSortField] : data2[event.field['field']];
          let result = null;

          if (value1 == null && value2 != null)
            result = -1;
          else if (value1 != null && value2 == null)
            result = 1;
          else if (value1 == null && value2 == null)
            result = 0;
          else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          else if (value1.name != null && value2 != null && value2.name != null)
            result = (value1.name < value2.name) ? -1 : (value1.name > value2.name) ? 1 : 0;

          return result;
      };

      let sortResult = innerSort(false);
      if (sortResult === 0 && this.prevSortField != null) {
        sortResult = innerSort(true);
      }
      this.prevSortField = event.field['field'];
      return (event.order * sortResult);
    });
  }

  public addRow() {
    if (this.organizationMode) {
      this.organizations.splice(0, 0, new Organization());
    }
    else {
      this.mappings.splice(0, 0, new OrganizationMapping());
    }
  }

  handleEditInit(event: any) {
    this.editingRowIndex = event.index;
    if (this.organizationMode && event.field === "organizationNumber") {
      (<Organization>this.tableData[event.index]).requireOrgNumber = false;
    }
  }
}
