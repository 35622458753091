import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, publishReplay, refCount, take } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service';
import { Broker } from '../domain/broker';
import { accountingSystems, currencies, Customer, IntegrationSetting, LedgerAccountSetting } from '../domain/customer';
import { DisplayMode } from '../domain/display-mode';
import { GlobalsService } from '../globals.service';
import { PortfolioService } from '../portfolio/portfolio.service';
import { AccountsService } from './accounts.service';
import { OrganizationSettingsComponent } from './organization-settings.component';

const CUSTODIAL_ACCOUNT_TOLLTIP = "Når dette alternativet er valgt bokfører vi transaksjoner på samme bank-konto uavhengig av megler. " +
                                  "For sluttsedler i andre valuta bokfører vi både i oppgjørsvaluta og i NOK. De fleste bør ikke velge dette alternativet.";
const EXCHANGE_RATES_TOOLTIP = "For sluttsedler i andre valuta henter vi vekslingskurs fra Norges Bank. " +
                               "Velg dette alternativet for å deaktivere denne funksjonen.";

export interface SettingsSubmittedDialogData {
  newCustomer: boolean;
  postSuccess: boolean;
  customer?: Customer;
  error?: string;
}

export interface ExportPerformedDialogData {
  success: boolean;
}

///////////////////////////////
// Settings submitted dialog //
///////////////////////////////
@Component({
  selector: 'settings-submitted-dialog',
  templateUrl: 'settings-submitted-dialog.component.html'
})
export class SettingsSubmittedDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: SettingsSubmittedDialogData) {}
}
///////////////////////////////
// Export performed dialog //
///////////////////////////////
@Component({
  selector: 'export-performed-dialog',
  templateUrl: 'export-performed-dialog.component.html'
})
export class ExportPerformedDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: boolean, public dialog: MatDialog) {}

  openExemptionSettingsDialog() {
    this.dialog.open(SecuritySettingsDialogComponent);
  }
}
///////////////////////////////
// Security settings dialog //
///////////////////////////////
@Component({
  selector: 'security-settings-dialog',
  templateUrl: 'security-settings-dialog.component.html'
})
export class SecuritySettingsDialogComponent {
  public displayModeSettings = DisplayMode.SECURITY_SETTINGS;
}
////////////////////
// Main component //
////////////////////
@Component({
  selector: 'customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss']
})

export class CustomerSettingsComponent implements OnInit, OnDestroy {
  public CUSTODIAL_ACCOUNT_TOLLTIP = CUSTODIAL_ACCOUNT_TOLLTIP;
  public EXCHANGE_RATES_TOOLTIP = EXCHANGE_RATES_TOOLTIP;
  public customer: Customer;
  public showAccountSettings = false;
  public brokerControl = new FormControl();
  public currencies = currencies;
  public settingsForm: FormGroup;
  // noinspection JSUnusedGlobalSymbols
  public accountingSystems = accountingSystems;
  public brokers: Observable<Array<Broker>>;
  public submitAsNewCustomer: boolean;
  private routeSubscription: Subscription;
  private custodialAccountSubscription: Subscription;
  private exportDialogSubscription: Subscription;
  public hideAccounts = this.globalsService.escaliMode;
  public hideOrgButton = !this.globalsService.escaliMode;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountsService: AccountsService,
    private portfolioService: PortfolioService,
    private authService: AuthService,
    public dialog: MatDialog,
    public globalsService: GlobalsService,
    private titleService: Title
) {
    this.createForm();
    this.titleService.setTitle("Innstillinger");
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.queryParamMap.subscribe(paramMap => {
      const event = paramMap.get('event');
      this.submitAsNewCustomer = event && (event === 'first-login' || event === 'precondition-failed' || event === 'create-customer');
      if (this.submitAsNewCustomer) {
        this.customer = new Customer();
        this.customer.id = -1;
        this.rebuildForm();
      }
      else {
        this.accountsService.getCustomerSettings()
          .pipe(
            catchError(err => {
              if (err.status === 412) {
                const customer = new Customer();
                customer.id = -1;
                return of(customer);
              }
            }),
            publishReplay(),
            refCount()
          )
          .subscribe(
            res => {
              this.customer = res;
              this.rebuildForm();
            }
          );
      }
    });
    this.setBrokers();
    this.handleCustodialAccountToggle();
  }

  ngOnDestroy() {
    if (this.custodialAccountSubscription) { this.custodialAccountSubscription.unsubscribe(); }
    if (this.routeSubscription) { this.routeSubscription.unsubscribe(); }
    if (this.exportDialogSubscription) { this.exportDialogSubscription.unsubscribe(); }
  }

  handleCustodialAccountToggle() {
    this.custodialAccountSubscription = this.settingsForm.get('usingCustodialAccount').valueChanges
      .subscribe(
        () => {
          this.setBankAccountSettings(this.customer.bankAccountSettings || []);
        }
    );
  }

  setBrokers(): void {
    this.brokers = this.portfolioService.getBrokers()
      .pipe(
        map(brokers => brokers.filter(broker => broker.name.length > 0)),
        publishReplay(),
        refCount()
      );
  }

  createForm(): void {
    this.settingsForm = this.formBuilder.group({
      customerName:           ['', [Validators.required, Validators.minLength(4)]],
      orgNumber:              ['', [Validators.required, Validators.pattern('[0-9]{9}')]],
      promptForExchangeRates: [''],
      usingCustodialAccount:  [''],
      bankAccountSettings:    this.formBuilder.array([]),
      ledgerAccountSettings:  this.formBuilder.array([]),
      integrationSettings:    this.buildIntegrationFormGroup(new IntegrationSetting())
    });
  }

  buildIntegrationFormGroup(values: IntegrationSetting) {
    return this.formBuilder.group({
      enabled: [values.enabled],
      autoExport: [{value: values.autoExport, disabled: !values.enabled}],
      accountingSystemId: [{value: values.accountingSystem.id || null, disabled: !values.enabled}, [Validators.required, Validators.minLength(2)]],
      clientKey: [{value: values.clientKey, disabled: !values.enabled || values.accountingSystem.id === 2}, [Validators.required, Validators.minLength(4)]],
      username: [{value: values.username, disabled: !values.enabled || values.accountingSystem.id !== 2}, [Validators.required, Validators.minLength(2)]],
      password: [{value: values.password, disabled: !values.enabled || values.accountingSystem.id !== 2}, [Validators.required, Validators.minLength(2)]]
    });
  }

  handleIntegrationSettingToggle(toggle) {
    if (toggle) {
      this.settingsForm.get('integrationSettings.accountingSystemId').enable();
      this.settingsForm.get('integrationSettings.autoExport').enable();
      this.handleAccountingSystemSelection(this.settingsForm.get('integrationSettings.accountingSystemId').value);
    }
    else {
      this.settingsForm.get('integrationSettings.accountingSystemId').disable();
      this.settingsForm.get('integrationSettings.autoExport').disable();
      this.settingsForm.get('integrationSettings.clientKey').disable();
      this.settingsForm.get('integrationSettings.username').disable();
      this.settingsForm.get('integrationSettings.password').disable();
    }
  }

  handleAccountingSystemSelection(id) {
    if (id === 2) {
      this.settingsForm.get('integrationSettings.clientKey').disable();
      this.settingsForm.get('integrationSettings.username').enable();
      this.settingsForm.get('integrationSettings.password').enable();
    }
    else if (id) {
      this.settingsForm.get('integrationSettings.clientKey').enable();
      this.settingsForm.get('integrationSettings.username').disable();
      this.settingsForm.get('integrationSettings.password').disable();
    }
  }

  exportNow() {
    this.exportDialogSubscription = this.accountsService.performExport()
      .subscribe(
        res => this.dialog.open(ExportPerformedDialogComponent, {data: res}),
        () => this.dialog.open(ExportPerformedDialogComponent, {data: false}),
      );
  }

  revertUnsavedChanges(): void {
    this.rebuildForm();
  }

  rebuildForm(): void {
    this.settingsForm.reset({
      customerName: this.customer.name || '',
      orgNumber: this.customer.organizationNumber || '',
      promptForExchangeRates: this.customer.promptForExchangeRates || '',
      usingCustodialAccount: this.customer.usingCustodialAccount || ''
    });
    this.setLedgerAccountSettings(this.customer.ledgerAccountSettings || []);
    this.setBankAccountSettings(this.customer.bankAccountSettings || []);
    this.settingsForm.setControl('integrationSettings', this.buildIntegrationFormGroup(this.customer.integrationSettings || new IntegrationSetting()));
  }

  isAlreadySelected(value: string | number): boolean {
    const usingCustodialAccount = this.settingsForm.getRawValue().usingCustodialAccount;
    const bankAccountSettings = this.settingsForm.getRawValue().bankAccountSettings;
    return bankAccountSettings &&
           bankAccountSettings
             .map(setting => usingCustodialAccount ? setting.currency : typeof setting.broker === 'number' ? setting.broker : setting.broker ? setting.broker.id : null)
             .includes(value);
  }

  setLedgerAccountSettings(accountSettings: Array<LedgerAccountSetting>) {
    this.settingsForm.setControl('ledgerAccountSettings', this.formBuilder.array(
      accountSettings.map(accountSetting => {
        return this.formBuilder.group({
          id: [accountSetting.id],
          accountNumber: [accountSetting.accountNumber, [Validators.required]],
          ledgerAccount: {id: accountSetting.ledgerAccount.id},
          shortName: [accountSetting.ledgerAccount.shortName]
        });
      })
    ));
  }

  setBankAccountSettings(accountSettings: Array<LedgerAccountSetting>) {
    const usingCustodialAccount = this.settingsForm.get('usingCustodialAccount').value;
    const accountSettingsFormGroups = accountSettings
      .filter(accountSetting => usingCustodialAccount ? accountSetting.currency !== null : accountSetting.broker !== null)
      .map(accountSetting => {
        return this.formBuilder.group({
          id: [accountSetting.id],
          accountNumber: [accountSetting.accountNumber, [Validators.required, Validators.pattern('[0-9]{4,5}')]],
          ledgerAccount: {id: accountSetting.ledgerAccount.id},
          broker:  [{value: accountSetting.broker, disabled: true}, usingCustodialAccount ? [] : [Validators.required]],
          currency: [{value: accountSetting.currency, disabled: true}, usingCustodialAccount ? [Validators.required] : []]
        });
      });
    const accountSettingsFormArray = this.formBuilder.array(accountSettingsFormGroups);
    this.settingsForm.setControl('bankAccountSettings', accountSettingsFormArray);
  }

  // Used by mat-select to determine if an externally set (broker) value corresponds to one of the values (brokers) in the brokers array
  compareBrokers(o1: Broker | number, o2: Broker | number): boolean {
    const id1 = typeof o1 === 'number' ? o1 : o1 ? o1.id : null;
    const id2 = typeof o2 === 'number' ? o2 : o2 ? o2.id : null ;
    return id1 === id2;
  }

  get ledgerAccountSettings(): FormArray {
    return this.settingsForm.get('ledgerAccountSettings') as FormArray;
  }

  get bankAccountSettings(): FormArray {
    return this.settingsForm.get('bankAccountSettings') as FormArray;
  }

  addBankAccountSetting() {
    const usingCustodialAccount = this.settingsForm.get('usingCustodialAccount').value;
    this.bankAccountSettings.push(this.formBuilder.group({
      accountNumber: ['', [Validators.required, Validators.pattern('[0-9]{4,5}')]],
      ledgerAccount: {id: this.customer.ledgerAccountSettings.find(accountSetting => accountSetting.ledgerAccount.accountCategory === "CASH").ledgerAccount.id},
      broker:  ['', usingCustodialAccount ? [] : [Validators.required, Validators.minLength(2)]],
      currency: ['', usingCustodialAccount ? [Validators.required, Validators.minLength(3), Validators.maxLength(3)] : []],
    }));
  }

  onSubmit() {
    const customerToSave = this.prepareSaveCustomerSettings();
    const isNewCustomer = customerToSave.id === -1 || this.submitAsNewCustomer;
    this.accountsService.saveCustomer(customerToSave, isNewCustomer)
      .subscribe(
        httpResponse => {
          if (isNewCustomer) {
            if (httpResponse.status === 201) {
              this.customer = httpResponse.body;
              this.rebuildForm();
              this.authService.renewToken();  // Renew token since user permissions might have changed to give user access to the created customer
              this.accountsService.clearAvailableCustomersCache();  // This cache was now invalid since available customers have changed
              this.openDialog(true, true, httpResponse.body);  // httpResponse.body should be the returned customer object
            }
            else {  // Submit (likely) failed, but we didn't receive an error response
              this.openDialog(true, false, null, httpResponse.status + ": " + httpResponse.statusText);
            }
          }
          else {
            this.customer = httpResponse.body;
            this.rebuildForm();
            this.openDialog(false, true);
          }
        },
        error => {
          console.log("error:");
          console.log(error);
          this.openDialog(isNewCustomer, false, null, error.status + ": " + error.error.error);
        }
      );
  }

  openExemptionSettingsDialog() {
    this.dialog.open(SecuritySettingsDialogComponent);
  }

  openOrganizationDialog() {
    this.dialog.open(OrganizationSettingsComponent, {minWidth: '600px'});
  }

  openDialog(newCustomer: boolean, postSuccess: boolean, customer?: Customer, error?: string): void {
    const dialogRef = this.dialog.open(
      SettingsSubmittedDialogComponent,
      {
        data: {
          newCustomer: newCustomer,
          postSuccess: postSuccess,
          customer: customer,
          error: error
        }
      }
    );
    dialogRef.afterClosed()
      .pipe(
        take(1)
      )
      .subscribe(
      () => {
        if (newCustomer && postSuccess && customer) {
          this.router.navigate(['/settings']);  // Without the query params
          this.accountsService.handleCustomerSelectionChange(customer.id);
        }
      },
        () => {
          location.reload();  // This shouldn't happen - reload and reset all values
        }
    );
  }

  prepareSaveCustomerSettings(): Customer {
    const formModel = this.settingsForm.value;

    // Some brokerForms are disabled, so the values have to be retrieved this way instead
    const bankFormGroups = this.settingsForm.controls.bankAccountSettings['controls'];
    const bankAccountSettings: Array<LedgerAccountSetting> = bankFormGroups
      .map(
        formGroup => {
          const broker = formGroup.controls.broker.value;
          return {
            id: formGroup.controls.id && formGroup.controls.id.value,
            accountNumber: formGroup.controls.accountNumber.value,
            ledgerAccount: formGroup.controls.ledgerAccount.value,
            broker: formModel.usingCustodialAccount ? null : typeof broker === 'number' ? {id: broker} : broker,
            currency: formModel.usingCustodialAccount ? formGroup.controls.currency.value : null
          };
        }
      );
    // return new Customer object containing a combination of original values
    // and deep copies of changed form model values
    const returnObj: Customer = {
      id : this.customer.id || null,
      name : formModel.customerName as string,
      organizationNumber :    formModel.orgNumber as number,
      promptForExchangeRates: !!formModel.promptForExchangeRates,
      usingCustodialAccount: !!formModel.usingCustodialAccount,
      ledgerAccountSettings : formModel.ledgerAccountSettings
    };
    if (bankAccountSettings && bankAccountSettings.length > 0) {
      returnObj.bankAccountSettings = bankAccountSettings;
    }
    if (formModel.integrationSettings && formModel.integrationSettings.accountingSystemId) {
      returnObj.integrationSettings = {
        enabled: !!formModel.integrationSettings.enabled,
        autoExport: !!formModel.integrationSettings.autoExport,
        accountingSystem: {
          id: +formModel.integrationSettings.accountingSystemId
        },
        clientKey: formModel.integrationSettings.clientKey,
        username: formModel.integrationSettings.username,
        password: formModel.integrationSettings.password
      };
    }
    return returnObj;
  }

  toggleAccountSettingsForm(): void {
    this.showAccountSettings = !this.showAccountSettings;
  }
}
