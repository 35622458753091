import { ComponentType } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ADD_TRANSACTION_COMPONENT } from '../add-transaction-component-token';
import { TransactionDetailsComponent } from './transaction-details.component';

export interface TransactionSubmittedDialogData {
  success: boolean;
  transactionId: number;
  deleteAttempt?: boolean;
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'transaction-submitted-dialog.component.html'
})
export class TransactionSubmittedDialogComponent {
  public retryButtonLabel: string;

  constructor(
    @Inject(ADD_TRANSACTION_COMPONENT) private addTransactionComponent: ComponentType<any>, // Injected like this to avoid circular dependency
    @Inject(MAT_DIALOG_DATA) public data: TransactionSubmittedDialogData,
    public selfDialogRef: MatDialogRef<TransactionSubmittedDialogComponent>,
    public dialog: MatDialog
  ) {
    this.retryButtonLabel = data.success ? "Registrer ny transaksjon" : "Prøv igjen";
  }

  onRetry(): void {
    this.dialog.open(this.addTransactionComponent, {data: {showAsDialog: true}, width: "90vw", minWidth: "800px", maxWidth: "95vw", height: "95vh", maxHeight: "95vh"});
  }

  openTransactionDetails(): void {
    this.dialog.open(TransactionDetailsComponent, {
      height: '95%',
      maxHeight: '95%',
      width: '85%',
      maxWidth: '85%',
      data: {
        transactionId: this.data.transactionId
      }
    });
  }
}
