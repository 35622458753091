<h5 class="title" id="dialog-header" style="text-align: center;">
    {{ organizationMode ? 'Tilknyttede selskaper' : 'Koblinger til selskaper' }}
</h5>
<mat-dialog-content style="padding-bottom: 10px">
    <div id="dialog-container">
        <p-table
            #orgTable
          (onEditCancel)="editingRowIndex = null"
          (onEditComplete)="handleEditComplete($event)"
          (onEditInit)="handleEditInit($event)"
          (sortFunction)="customSort($event)"
          [autoLayout]="true"
          [columns]="tableColumns"
          [customSort]="true"
          [globalFilterFields]="getFilterFields()"
          [pageLinks]="5"
          [responsive]="true"
          [rows]="rowsPerPage"
          [totalRecords]="totalRecords"
          [paginator]="totalRecords > rowsPerPage"
          [value]="tableData"
          paginatorDropdownAppendTo="body"
          styleClass="defaultRowStyleClass"
          tableStyleClass="customStripes"
        >
            <ng-template pTemplate="caption">
                <div class="header-item">
                    <button (click)="addRow()" class="theme-button header-button" mat-button type="button">
                        <mat-icon aria-label="Last ned">add</mat-icon>  {{ organizationMode ? 'Nytt selskap' : 'Ny kobling' }}
                    </button>
                </div>
                <div class="header-item">
                    <button (click)="toggleOrganizationMode()" class="theme-button header-button" mat-button type="button">
                        <mat-icon aria-label="Bytt">reorder</mat-icon>  Rediger {{ organizationMode ? 'koblinger' : 'selskaper' }}
                    </button>
                </div>
                <div class="header-item" style="text-align: left;">
                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                    <input (input)="orgTable.filterGlobal($event.target['value'], 'contains')" autofocus class="globalfilter" pInputText placeholder="Søk" size="50"
                      style="width:auto" type="text"
                    >
                </div>
            </ng-template>
            <ng-template let-columns pTemplate="header">
                <tr>
                    <ng-container *ngFor="let col1 of columns">
                        <!-- Sortable header col -->
                        <ng-container *ngIf="tableData?.length > 1 && col1.field != 'deleteButton'; else nonSortableCol">
                            <th [pSortableColumn]="col1" class="tbl-header">
                                <p-sortIcon *ngIf="tableData?.length > 1" [field]="col1.field"></p-sortIcon>
                                {{col1.header}}
                            </th>
                        </ng-container>
                        <!-- Non-sortable header col-->
                        <ng-template #nonSortableCol>
                            <th class="tbl-header">{{col1.header}}</th>
                        </ng-template>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template let-columns="columns" let-index="rowIndex" let-rowData pTemplate="body">
                <tr [ngClass]="{'not-editing': editingRowIndex !== index}" class="tbl-data-row">
                    <td *ngFor="let col3 of columns"
                      [ngClass]="{'empty-cell': getCellData(rowData, col3.field) == null}"
                      [pEditableColumnField]="col3.field"
                      [pEditableColumnRowIndex]="index"
                      [pEditableColumn]="rowData"
                      id="editable-cell"
                    >
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input *ngIf="col3.type === 'text'"
                                  [(ngModel)]="rowData[col3.field]"
                                  [name]="col3.field"
                                  [placeholder]="col3.header.toLowerCase()"
                                  class="form-input cellTextInput"
                                  id="cell-text-input"
                                  pInputText
                                  type="text"
                                />
                                <mat-select *ngIf="col3.field === 'organization' || col3.field === 'broker'"
                                  [(ngModel)]="rowData[col3.field]"
                                  [compareWith]="compareById"
                                  class="mat-select-invalid"
                                  [name]="col3.header.toLocaleLowerCase()"
                                  [placeholder]="'Velg ' + col3.header.toLowerCase()"
                                  id="selection-input" style="padding-left: 6px; padding-right: 4px;"
                                >
                                    <mat-option *ngFor="let item of getOptions(col3.field)" [value]="item">{{ item.name }}</mat-option>
                                </mat-select>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span *ngIf="col3.field !== 'deleteButton'; else deleteBtn">
                                    {{ getCellData(rowData, col3.field) || (rowData?.id ? '' : col3.placeholder) }}
                                </span>
                                <ng-template #deleteBtn>
                                    <button
                                      (click)="handleDelete(rowData); $event.stopPropagation()"
                                      *ngIf="col3.field === 'deleteButton' && isValid(rowData) && editingRowIndex !== index"
                                      [disableRipple]="false"
                                      class="delete-button"
                                      mat-icon-button
                                      type="button">
                                        <mat-icon [inline]="true" aria-label="Slett" style="font-family: 'Material Icons', sans-serif;">delete</mat-icon>
                                    </button>
                                </ng-template>
                            </ng-template>
                            y
                        </p-cellEditor>
                    </td>
                </tr>
            </ng-template>
            <ng-template let-columns pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="tableColumns.length">
                        {{hasError ? 'Feil ved henting av data' : isComplete ? 'Ingen data funnet' : 'Henter data...'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix" style="justify-content: center">
    <button class="theme-button" mat-button mat-dialog-close type="button">Ok</button>
</mat-dialog-actions>
