export class Filter {
  key: string;
  // Defaults to eq (server-side). First operator is applied first, e.g. [eq, ci, not] --> not(ci(eq(value)))
  operators?: Array<Op>;
  // Filter will not be applied if value == null
  value?: string | number;

  constructor(key: string, operators?: Array<Op> | Op, value?: string | number) {
    this.key = key;
    this.operators = Array.isArray(operators) ? operators : [operators];
    this.value = value;
  }

  // firstChar: One of '?', '&', ''
  // Will return empty string without firstChar if value == null
  getQueryString(firstChar: string): string {
    if (this.value == null || this.value === '') {
      return '';
    }
    let val = this.value.toString();
    if (!this.operators || this.operators.length === 0 || (this.operators.length === 1 && this.operators[0] === Op.eq)) {
      return `${firstChar}${this.key}=${val}`;
    }
    this.operators.forEach(operator => {
      if (operator != null) {
        val = `${this.getEnumName(operator)}(${val})`;
      }
    });
    return `${firstChar}${this.key}=${val}`;
  }

  getEnumName(operator: Op): string {
    const keys = Object.keys(Op).filter(key => Op[key] === operator);
    return keys.length > 0 ? keys[0] : null;
  }
}

// see https://bitbucket.org/gt_tech/spring-data-querydsl-value-operators/src/master/
export enum Op {
  // noinspection JSUnusedGlobalSymbols
  eq,  // equals (default)
  ne,  // not equals
  startsWith,
  endsWith,
  contains,
  ci,  // makes other modes case-insensitive
  matches,  // regex
  gt,  // greater than
  gte,  // greater than or equal
  lt,  // less than
  lte,  // less than or equal
  not
}
