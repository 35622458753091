import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, finalize, first, map, publishReplay, refCount, share, startWith, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service';
import { Customer } from '../domain/customer';
import { AccountsService } from './accounts.service';

@Component({
  selector: 'app-user-dropdown-menu',
  templateUrl: './user-dropdown-menu.component.html'
})

export class UserDropdownMenuComponent implements OnInit, OnDestroy {
  private authSubscription: Subscription;
  public menuItems: Array<MenuItem>;
  public displayCustomerDropdown: boolean;
  public allowCreateNewCustomer: boolean;

  public defaultCustomerName: string;
  public customerList: Array<Customer>;
  private refreshCustomerList = new Subject<void>();
  public selectedCustomer: Observable<Customer>;
  public selectedCustomerId: number;
  public selectedCustomerOverride: number;

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.menuItems = [
      {
        label: 'Innstillinger',
        icon: 'fa fa-cog',
        command: () => this.router.navigate(['/settings'])
      }
    ];
    this.selectedCustomerId = this.accountsService.getSelectedCustomerId();

    // If user isn't yet authenticated, it's possible that the initial auth process hasn't yet completed.
    // In that case, subscribe to changes in the auth status and initialize if/when user is successfully authenticated
    if (this.authService.isAuthenticated()) {
      this.initializeValues();
    }
    else {
      this.authSubscription = this.authService.authResult
        .pipe(
          first(),
          catchError(() => of(false))
        )
        .subscribe(
        result => {
          if (result) {  // A successful auth event
            this.initializeValues();
          }
        }
      );
    }
  }

  initializeValues(): void {
    this.checkUserScopes();
    this.selectedCustomer = this.getSelectedCustomer();
    this.refreshCustomerList.pipe(
      startWith(undefined),
      switchMap(() => {
        return this.getAvailableCustomers().pipe(
          map(results => results.sort((a, b) => a.name.localeCompare(b.name)))
        )
          .pipe(
            share(),
            finalize(() => {  // overriding selectedCustomerId as soon as it has been retrieved from the api
              if (this.selectedCustomerOverride) {
                this.selectedCustomerId = this.selectedCustomerOverride;
                this.onCustomerSelectionChange(this.selectedCustomerOverride);
                this.selectedCustomerOverride = void 0;
              }
            })
          );
      })
    ).subscribe(
      customerList => {
        if (customerList.length > 0) {
          this.customerList = customerList;
          let subArr: Array<MenuItem>;
          subArr = customerList.map(customer => {
            return {
              label: customer.name,
              id: customer.id.toString(),
              command: (event) => this.onCustomerSelectionChange(event.item.id)
            };
          });
          this.menuItems.unshift({
            label: 'Bytt kunde',
            icon: 'fa fa-exchange',
            items: subArr
          });
        }
      }
    );
  }

  getSelectedCustomer(): Observable<Customer> {
    return this.accountsService.getCustomerSettings()
      .pipe(
        tap(customer => {
          if (customer.name && customer.name.length > 0) {
            this.defaultCustomerName = customer.name;
          }
        }),
        catchError(err => {
          if (err.status === 412) {
            const customer = new Customer();
            customer.id = -1;
            return of(customer);
          }
          else {
            throwError(err);
          }
        }),
        publishReplay(),
        refCount()
      );
  }

  getAvailableCustomers(): Observable<Array<Customer>> {
    return this.accountsService.getAvailableCustomers()
      .pipe(
        map(arr => arr.sort((x, y) => x.id < y.id ? -1 : 1)),  // Sort by id
        share(),
      );
  }

  // Returns true if users has the required scope to manage other customer accounts
  public checkUserScopes(): boolean {
    const scopes = this.authService.getGrantedScopes();
    if (scopes) {
      const displayCustomerRegex = /:(all|customer)/i;
      this.displayCustomerDropdown = displayCustomerRegex.test(scopes);
      const createCustomerRegex = /(all:all|create:customer)/i;
      if (createCustomerRegex.test(scopes)) {
        this.allowCreateNewCustomer = true;
        this.menuItems.push({
          label: 'Opprett ny kunde',
          icon: 'fa fa-user-plus',
          command: () => this.router.navigate(['/settings'], {queryParams: {event: 'create-customer'}})
        });
      }
      this.allowCreateNewCustomer = createCustomerRegex.test(scopes);
    }
    else {
      return false;
    }
  }

  onCustomerSelectionChange(selectedId: number) {
    if (selectedId !== -1) {  // If user selected another customer to switch to
      this.accountsService.handleCustomerSelectionChange(selectedId);
    }
  }

  ngOnDestroy() { if (this.authSubscription) { this.authSubscription.unsubscribe(); } }
}
