import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        catchError((event) => {
          if (event instanceof HttpErrorResponse) {
            return this.handleError(event);
          }
        }));
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    // Precondition failed - used when a user is not associated with any customers and did not specify a customer param
    if (error.status === 412) {
      this.router.navigate(['/settings'], {queryParams: {event: 'precondition-failed'}});
    }
    return throwError(error);
  }
}
