import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

import { take } from 'rxjs/operators';
import { Broker } from '../../domain/broker';
import { Note } from '../../domain/note';
import { Security } from '../../domain/security';
import { Transaction } from '../../domain/transaction';

import { PortfolioService } from '../../portfolio/portfolio.service';
import { TransactionSubmittedDialogComponent, TransactionSubmittedDialogData } from '../transaction-submitted-dialog.component';
import { TransactionService } from '../transaction.service';
import { SelectInstrumentDialogComponent } from './select-instrument-dialog.component';

@Component({
  selector: 'add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.scss']
})

export class AddTransactionComponent implements OnInit {

  public brokers: Array<Broker>;
  public fileUploaded = false;
  public typeSelected = false;

  public title = "Registrer ny transaksjon";
  public placeholderPortfolioItem = {security: {name: 'Velg verdipapir'}};
  public selectedPortfolioItem: any = Object.assign(this.placeholderPortfolioItem);
  public currencyOptions = ['NOK', 'SEK', 'DKK', 'EUR', 'USD', 'GBP'];

  public newTransaction: Transaction = new Transaction();
  public automaticMode = true;
  public showAsDialog = false;

  constructor(
    public selfDialogRef: MatDialogRef<AddTransactionComponent>,
    private portfolioService: PortfolioService,
    private transactionService: TransactionService,
    private dialog: MatDialog,
    private titleService: Title,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data && data.transaction) {
      this.title = "Oppdater transaksjon";
      this.automaticMode = false;
      if (data) {
        this.showAsDialog = data.showAsDialog;
        if (!this.showAsDialog) {
          this.titleService.setTitle("Ny transaksjon");
        }
        if (data.transaction) {
          data.transaction.note = data.transaction.note || new Note();
          data.transaction.broker = data.transaction.broker || new Broker();
          data.transaction.portfolioItem = data.transaction.portfolioItem || {security: new Security()};
          if (data.transaction.transactionType != null) {
            this.typeSelected = true;
          }
          if (data.transaction.portfolioItem && data.transaction.portfolioItem.security) {
            this.selectedPortfolioItem = data.transaction.portfolioItem;
          }
          this.newTransaction = data.transaction;
        }
      }
    }
  }

  ngOnInit(): void {
    this.getYearRange();

    this.portfolioService.getBrokers().pipe(take(1))
      .subscribe(brokers => this.brokers = brokers);
  }

  onNoteSubmit(event) {
    this.transactionService.postNote(event.target.files[0])
      .subscribe(
        res => {
          if (res) {
            this.portfolioService.clearPortfolioCache();
            this.transactionService.clearLedgerCache();
            this.transactionService.transactionsChangedNotifier.next();
            this.transactionService.ledgerChangedNotifier.next();
            this.portfolioService.portfolioChangedNotifier.next();
            this.openConfirmationDialog(true, res[0].id);
          }
          else {
            this.openConfirmationDialog(false);
          }
        },
        () => {
          this.openConfirmationDialog(false);
        }
      );
  }

  openSelectInstrumentDialog() {
    const dialogRef = this.dialog.open(SelectInstrumentDialogComponent);
    dialogRef.afterClosed()
      .pipe(
        take(1)
      )
      .subscribe(
        result => {
          if (result) {  // Check necessary in case user dismissed dialog
            this.selectedPortfolioItem = result.data;
            this.newTransaction.portfolioItem = result.data;
          }
        },
        () => {
          location.reload();  // This shouldn't happen - reload and reset all values
        }
      );
  }

  openConfirmationDialog(success: boolean, transactionId?: number, deleteAttempt?: boolean) {
    const dialogData: TransactionSubmittedDialogData = {
      success: success,
      transactionId: transactionId,
      deleteAttempt: deleteAttempt
    };
    this.dialog.open(TransactionSubmittedDialogComponent, {data: dialogData});
    if (this.selfDialogRef) {
      this.selfDialogRef.close();
    }
  }

  submitNewTransaction(): void {
    // Isin is stored after tx is submitted in case user wants to navigate to detail view for the submitted transaction
    this.transactionService.saveTransaction(this.prepareSaveTransaction(this.newTransaction), this.showAsDialog)
      .subscribe(
        res => {
          if (res) {
            this.portfolioService.clearPortfolioCache();
            this.transactionService.clearLedgerCache();
            this.transactionService.transactionsChangedNotifier.next();
            this.transactionService.ledgerChangedNotifier.next();
            this.portfolioService.portfolioChangedNotifier.next();
            this.openConfirmationDialog(true, res.id);
          }
          else {
            this.openConfirmationDialog(false);
          }
        },
        () => {
          this.openConfirmationDialog(false);
        }
      );
  }

  prepareSaveTransaction(transaction: Transaction): Transaction {
    transaction.transactionStatus = 'ACTIVE';
    const copy: Transaction = JSON.parse(JSON.stringify(transaction));
    // Files doesn't carry over during the json parsing above, so set it from original
    if (copy.note) {
      copy.note.content = transaction.note.content;
    }

    // Delete values that the user has not been prompted for, but may need to be updated. These are values that will be automatically inferred/calculated by the backend.
    if (copy.transactionCurrency === 'NOK') {
      delete copy.amountNOK;
    }
    else if (copy.settlementCurrency === 'NOK' && copy.amount && copy.amountNOK) {
      delete copy.exchangeRate;
    }
    return copy;
  }

  deleteTransaction() {
    if (!this.newTransaction || !this.newTransaction.id) {
      this.openConfirmationDialog(false);
    }
    this.transactionService.deleteTransaction(this.newTransaction.id)
      .subscribe(
        result => {
          if (result) {
            this.openConfirmationDialog(true, undefined, true);
          }
          else {
            this.openConfirmationDialog(false, undefined, true);
          }
        },
        () => this.openConfirmationDialog(false, undefined, true)
      );
  }

  onNoteSelect(event): void {
    const file = event.target.files[0] as File;
    const selectedNote = new Note();
    selectedNote.content = file;
    selectedNote.fileName = file.name;
    selectedNote.setContentTypeFromString(file.type);
    if (file.type === 'text/plain') {
      this.readTextFile(event.target.files[0]).then(
        content => {
          selectedNote.content = content as string;
          this.newTransaction.note = selectedNote;
      }).catch(
        error => console.log(error)
      );
    }
    else {
      selectedNote.content = file;
      this.newTransaction.note = selectedNote;
    }
    this.newTransaction.note = selectedNote;
    this.fileUploaded = true;
  }

  readTextFile(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = event => resolve(event.target['result']);
      reader.onerror = error => reject(error);
      reader.readAsText(file, 'ISO-8859-1');
    });
  }

  onTypeChange(value) {
    this.typeSelected = true;
    this.newTransaction.transactionType = value;
  }

  getYearRange(): string {
    const currentYear = new Date().getFullYear();
    return ((currentYear - 5).toString()).concat(':').concat(currentYear.toString());
  }

  handleTransactionDateSelect() {
    if (!this.newTransaction?.settlementDate) {
      this.newTransaction.settlementDate = this.newTransaction.transactionDate;
    }
  }
}
