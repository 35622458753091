<p-table
  #portfolioTable
  [value]="portfolio"
  [columns]="tableColumns"
  [responsive]="true"
  [autoLayout]="true"
  [paginator]="showPaginator"
  [totalRecords]="totalRecords"
  [rowsPerPageOptions]="[15, 25, 50, 100]"
  [pageLinks]="5"
  [rows]="rowsPerPage"
  [lazy]="true"
  [lazyLoadOnInit]="false"
  (onLazyLoad)="lazyLoadPortfolio($event)"
  (onEditComplete)="handleCellEdit($event)"
  tableStyleClass="customStripes"
  paginatorDropdownAppendTo="body"
  styleClass="defaultRowStyleClass"
>
    <ng-template *ngIf="displayMode === displayModeDict.all || displayMode === displayModeDict.securitySettings" pTemplate="caption">
        <div class="header-item" style="text-align: left">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input autofocus #globalFilterInput class="globalfilter" [(ngModel)]="templateValues.globalFilter" [disabled]="!!templateValues.toDateFilter" type="text" pInputText size="50" placeholder="Søk"
              (input)="portfolioTable.filterGlobal($event.target.value, 'contains')" style="width:auto"
            >
        </div>
        <ng-container *ngIf="displayMode === displayModeDict.all">
            <div class="theme-calendar header-item">
                <p-calendar
                  dateFormat="dd.mm.yy"
                  (onSelect)="globalFilterInput.value = ''; portfolioTable.filterGlobal('', 'contains'); updateFilterValues({toDate: $event})"
                  [(ngModel)]="templateValues.toDateFilter"
                  dataType="string"
                  [defaultDate]="getLastYearEnd()"
                  [showIcon]="!templateValues.toDateFilter"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  [yearRange]="getYearRange()"
                  name="toDate"
                  [readonlyInput]="true"
                  placeholder="Til dato"
                  appendTo="body"
                ></p-calendar>
                <button mat-icon-button class="theme-button date-btn" type="button"
                  *ngIf="!!templateValues.toDateFilter"
                  (click)="globalFilterInput.value = ''; updateFilterValues({toDate: null});"
                >
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
            <div class="header-item ui-helper-clearfix">
                <mat-checkbox
                  [(ngModel)]="templateValues.unitsFilterChecked"
                  [disabled]="!!templateValues.toDateFilter"
                  class="theme-input"
                  (change)="updateFilterValues({hideZeroBalance: !$event.checked})"
                  labelPosition="before"
                >
                    Vis tidligere eide verdipapir
                </mat-checkbox>
            </div>
        </ng-container>
        <div class="header-item">
            <button (click)="exportPortfolioToExcel()" class="theme-button" id="export-button" mat-button type="button">
                <mat-icon aria-label="Last ned">cloud_download</mat-icon>  Last ned
            </button>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col1 of columns">
                <!-- Sortable header col -->
                <ng-container *ngIf="portfolio?.length > 1; else nonSortableCol">
                    <th [pSortableColumn]="col1.field">
                        <p-sortIcon *ngIf="portfolio?.length > 1" [field]="col1.field"></p-sortIcon>
                        {{col1.header}}
                    </th>
                </ng-container>
                <!-- Non-sortable header col-->
                <ng-template #nonSortableCol>
                    <th>{{col1.header}}</th>
                </ng-template>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr *ngIf="displayMode !== displayModeDict.securitySettings; else editableCells">
            <td *ngFor="let col2 of columns" [ngSwitch]="col2.type">
                <a
                  *ngSwitchCase="'assetLink'"
                  [routerLink]="['/details/', rowData.security.isin]"
                  class="tableLink"
                >
                    {{getCellData(rowData, col2.field)}}
                </a>
                <p *ngSwitchCase="'securityType'">
                    {{getSecurityTypeText(getCellData(rowData, col2.field))}}
                </p>
                <p *ngSwitchCase="'decimal'" style="text-align: right">
                    {{getCellData(rowData, col2.field) | number: '1.2-2'}}
                </p>
                <p *ngSwitchCase="'integer'" style="text-align: right">
                    {{getCellData(rowData, col2.field) | number: '1.0-0'}}
                </p>
                <p *ngSwitchCase="'exemptionMethod'" style="text-align: right">
                    {{getCellData(rowData, col2.field) === true ? 'Ja' : 'Nei'}}
                </p>
                <p *ngSwitchDefault>
                    {{getCellData(rowData, col2.field)}}
                </p>
            </td>
        </tr>
        <ng-template #editableCells>
            <tr>
                <td id="editable-cell" [pEditableColumn]="rowData" *ngFor="let col3 of columns">
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input pInputText
                              *ngIf="col3.type !== 'exemptionMethod'; else exemptionInput"
                              class="form-input cellTextInput"
                              id="cell-text-input"
                              [name]="col3.field"
                              [(ngModel)]="rowData[col3.field.split('.')[0]][col3.field.split('.')[1]]"
                              type="text"
                              [placeholder]="col3.header.toLowerCase()"
                            />
                            <ng-template #exemptionInput>
                                    <mat-select id="cell-exemption-input" name="transactionCurrency" placeholder="Velg valuta*" [(ngModel)]="rowData[col3.field]">
                                        <mat-option [value]="true">Ja</mat-option>
                                        <mat-option [value]="false">Nei</mat-option>
                                    </mat-select>
                            </ng-template>
                        </ng-template>
                        <ng-template pTemplate="output">
                            {{
                            col3.type === 'exemptionMethod'
                              ? getCellData(rowData, col3.field) === true ? 'Ja' : 'Nei'
                              : getCellData(rowData, col3.field)
                            }}
                        </ng-template>
                    </p-cellEditor>
                </td>
            </tr>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="tableColumns.length">
                {{hasError ? 'Feil ved henting av data' : isComplete ? 'Ingen data funnet' : 'Henter data...'}}
            </td>
        </tr>
    </ng-template>
</p-table>
