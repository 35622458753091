<h5 id="dialog-header" class="title">
    Detaljer
</h5>
<mat-dialog-content>
    <div id="dialog-container" *ngIf="transaction">
        <h2 class="title">Verdipapirtransaksjon</h2>
        <my-transactions
          [displayMode]="displayModeTransaction"
          [transactionToShow]="transaction"
          [transactionId]="data?.transactionId"
        ></my-transactions>
        <my-ledger *ngIf="!hideAccounts" [displayMode]="displayModeTransaction" [transactionId]="transaction.id" [ledgerEntries]="transaction.ledgerEntries"></my-ledger>
        <my-note *ngIf="transaction?.note?.content" [note]="transaction?.note"></my-note>
    </div>
</mat-dialog-content>
<mat-dialog-actions
  class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"
  style="justify-content: center;"
>
    <button mat-button mat-dialog-close class="theme-button" type="button">Ok</button>
</mat-dialog-actions>
