import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Page } from './domain/page';
import { SecurityTypes } from './domain/security.types';
import { TableColumn } from './domain/table-column';
import { TransactionDetailsComponent } from './transactions/transaction-details.component';

@Component({template: ''})
export class AbstractTableComponent<T> {
  
  public diagDimensions = {
    height: '95%',
    maxHeight: '95%',
    width: '85%',
    maxWidth: '85%'
  };

  constructor(public dialog?: MatDialog) { }
  
  public getLastYearEnd(): Date {
    return new Date(new Date().getFullYear() - 1, 11, 31);
  }

  public getLastYearStart(): Date {
    return new Date(new Date().getFullYear() - 1, 0, 1);
  }

  // Returns years in the format 2009:2019
  public getYearRange(numYears = 10): string {
    return ((new Date().getFullYear() - numYears).toString()).concat(':').concat(new Date().getFullYear().toString());
  }

  public parseDate(date: Date): string {
    if (!date) {
      return null;
    }
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().replace(/T.+/, '');
  }
  
  public getCellData(rowData: any, field: string): any {
    const nestedProperties: string[] = field.split('.');
    let value: any = rowData;
    for (const prop of nestedProperties) {
      if (value == null) {
        return '';
      }
      value = value[prop];
    }
    return value;
  }

  openTransactionDetails(transactionId: number): void {
    this.dialog.open(TransactionDetailsComponent, {
      height: this.diagDimensions.height,
      maxHeight: this.diagDimensions.maxHeight,
      width: this.diagDimensions.width,
      maxWidth: this.diagDimensions.maxWidth,
      data: {
        transactionId: transactionId
      }
    });
  }

  getSecurityTypeText(securityType: string): string {
    const types = new SecurityTypes();
    return Object.keys(types).find(key => types[key] === securityType);
  }

  exportDataToExcel(observable: Observable<Page<T>>, tableName: string, tableColumns: Array<TableColumn>) {
    observable.subscribe(
        portfolioPage => {
          import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(portfolioPage.content.map(row => this.parseRowForExport(row, tableColumns)));
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, tableName);
          });
        }
      );
  }

  // Parse according to tableColumns headers and fieldnames
  parseRowForExport(row: T, tableColumns: Array<TableColumn>): any {
    const getFieldValue = (data, field) => {
      for (let subfield of field.split(".")) {
        data = data[subfield];
      }
      return data;
    };
    const mappedRow = {};
    for (let col of tableColumns) {
      mappedRow[col.header] = getFieldValue(row, col.field);
      if (col.type == "securityType") {
        mappedRow[col.header] = this.getSecurityTypeText(getFieldValue(row, col.field));
      }
      else if (col.type == "transactionType") {
        if (getFieldValue(row, col.field) === "PURCHASE"){
          mappedRow[col.header] = "Kjøp";
        }
        else if (getFieldValue(row, col.field) === "SALE"){
          mappedRow[col.header] = "Sale";
        }
        else if (getFieldValue(row, col.field) === "DIVIDEND"){
          mappedRow[col.header] = "Utbytte";
        }
        //mappedRow[col.header] = getFieldValue(row, col.field) === "PURCHASE" ? "Kjøp" : "Salg";
      }
      else if (col.type == "exemptionMethod") {
        mappedRow[col.header] = getFieldValue(row, col.field) === true ? "Ja" : "Nei";
      }
      else {
        mappedRow[col.header] = getFieldValue(row, col.field);
      }
    }
    return mappedRow;
  }

  saveAsExcelFile(buffer: any, tablename: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
      FileSaver.saveAs(data, 'Secbase ' + tablename + '.xlsx');
    });
  }
}
