import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DisplayMode } from 'src/app/domain/display-mode';
import { Transaction } from '../domain/transaction';
import { GlobalsService } from '../globals.service';

import { TransactionService } from './transaction.service';


export interface TransactionDialogData {
  transactionId: number;
  dimensions: {
    height: string;
    maxHeight: string;
    width: string;
    maxWidth: string;
  };
}

@Component({
  selector : 'transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls : ['./transaction-details.component.scss']
})

export class TransactionDetailsComponent implements OnInit {
  public displayModeTransaction = DisplayMode.TRANSACTION;
  public transaction: Transaction;
  public hideAccounts = this.globalsService.escaliMode;

  public hasError = false;
  public isComplete = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TransactionDialogData,
    private transactionService: TransactionService,
    private globalsService: GlobalsService
  ) { }

  ngOnInit(): void {
    this.transactionService.getSingleTransaction(this.data.transactionId).subscribe(transaction => {
      this.transaction = transaction;
      this.hasError = false;
    },
      error => {
        console.log("Error getting transaction:");
        console.log(error);
        this.hasError = true;
      },
      () => this.isComplete = true
      );
  }
}
