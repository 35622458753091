<h5 *ngIf="!!data" mat-dialog-title class="title" style="text-align: center; font-size: x-large">
  Eksport til regnskapssystem fullført
</h5>
<h5 *ngIf="!data" mat-dialog-title class="title" style="text-align: center; font-size: x-large; color: red;">
  Feil ved eksportering av transaksjoner
</h5>
<div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix" style="border: none; text-align: center">
  <button mat-button mat-dialog-close type="button" class="theme-button" style="margin: 5px 5px 20px 5px">Ok</button>
  <button mat-button mat-dialog-close *ngIf="!!data" class="theme-button" type="button" (click)="openExemptionSettingsDialog()">
    <mat-icon aria-label="Vis Behandling av Fritaksmetoden">open_in_new</mat-icon>  Vis Behandling av Fritaksmetoden
  </button>
</div>
