import { LayoutModule } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeNb from '@angular/common/locales/nb';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';

import { TableModule } from 'primeng/table';
import { AbstractTableComponent } from './abstract-table.component';
import { AccountsService } from './accounts/accounts.service';
import { CustomerSettingsComponent, ExportPerformedDialogComponent, SecuritySettingsDialogComponent, SettingsSubmittedDialogComponent } from './accounts/customer-settings.component';
import { OrganizationSettingsComponent } from './accounts/organization-settings.component';
import { SettingsInterceptor } from './accounts/settings-interceptor';
import { UserDropdownMenuComponent } from './accounts/user-dropdown-menu.component';
import { ADD_TRANSACTION_COMPONENT } from './add-transaction-component-token';
import { AppRoutingModule, ChainedGuard } from './app-routing.module';
import { AppComponent } from './app.component';
import { AssetDetailsComponent } from './asset-details.component';
import { AuthGuardService } from './authentication/auth-guard.service';
import { AuthService } from './authentication/auth.service';
import { AuthenticationInterceptor } from './authentication/authentication-interceptor';
import { LoginComponent } from './authentication/login.component';
import { BalanceComponent } from './balance/balance.component';
import { BalanceService } from './balance/balance.service';
import { DashboardComponent } from './dashboard.component';
import { GlobalsService } from './globals.service';
import { HttpErrorInterceptor } from './http-error-interceptor';
import { HttpRedirectService } from './http-redirect.service';
import { LedgerEntriesComponent } from './ledger-entries.component';
import { NoteComponent } from './note.component';
import { CellEditErrorDialogComponent, PortfolioComponent } from './portfolio/portfolio.component';
import { PortfolioService } from './portfolio/portfolio.service';
import { AddInstrumentDialogComponent } from './transactions/add-transaction/add-instrument-dialog.component';
import { AddTransactionComponent } from './transactions/add-transaction/add-transaction.component';
import { SelectInstrumentDialogComponent } from './transactions/add-transaction/select-instrument-dialog.component';
import { TransactionDetailsComponent } from './transactions/transaction-details.component';
import { TransactionSubmittedDialogComponent } from './transactions/transaction-submitted-dialog.component';
import { TransactionService } from './transactions/transaction.service';
import { TransactionsComponent } from './transactions/transactions.component';

registerLocaleData(localeNb);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AssetDetailsComponent,
    PortfolioComponent,
    BalanceComponent,
    TransactionsComponent,
    AddTransactionComponent,
    TransactionDetailsComponent,
    LoginComponent,
    CustomerSettingsComponent,
    SettingsSubmittedDialogComponent,
    UserDropdownMenuComponent,
    SelectInstrumentDialogComponent,
    AddInstrumentDialogComponent,
    TransactionSubmittedDialogComponent,
    ExportPerformedDialogComponent,
    LedgerEntriesComponent,
    AbstractTableComponent,
    SecuritySettingsDialogComponent,
    OrganizationSettingsComponent,
    NoteComponent,
    CellEditErrorDialogComponent
  ],
  entryComponents: [  // Needed for dialog components
    TransactionDetailsComponent,
    SettingsSubmittedDialogComponent,
    SelectInstrumentDialogComponent,
    AddInstrumentDialogComponent,
    TransactionSubmittedDialogComponent,
    ExportPerformedDialogComponent,
    SecuritySettingsDialogComponent,
    OrganizationSettingsComponent,
    AddTransactionComponent,
    CellEditErrorDialogComponent
  ],
  imports : [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    PdfViewerModule,
    // Primeng imports
    AutoCompleteModule,
    ButtonModule,
    DialogModule,
    OverlayPanelModule,
    SelectButtonModule,
    CalendarModule,
    InputTextModule,
    InputMaskModule,
    FileUploadModule,
    BrowserAnimationsModule,
    ListboxModule,
    DropdownModule,
    SlideMenuModule,
    KeyFilterModule,
    // Angular material imports
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatGridListModule,
    MatDialogModule,
    MatCheckboxModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    LayoutModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatTooltipModule,
    TableModule
  ],
  providers: [
    GlobalsService,
    PortfolioService,
    TransactionService,
    BalanceService,
    AuthGuardService,
    HttpRedirectService,
    ChainedGuard,
    AuthService,
    AccountsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'nb'  // Change to 'any' to determine number format based on browser settings
    },
    {provide: MatDialogRef, useValue: {}},
    {provide:MAT_DIALOG_DATA,useValue:{}},
    {provide: ADD_TRANSACTION_COMPONENT, useValue: AddTransactionComponent}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer, globalsService: GlobalsService) {
    if (globalsService.bookkeeperMode) {
      overlayContainer.getContainerElement().classList.add('bookkeeper');
    }
    else if (globalsService.escaliMode) {
      overlayContainer.getContainerElement().classList.add('escali');
    }
  }
}

