<div class="note-submit" *ngIf="automaticMode">
    <button mat-button class="theme-button" type="button" (click)="noteSubmit.click()">
        <mat-icon id="note-upload-icon" aria-label="Last ned">cloud_upload</mat-icon>  Last opp sluttseddel
    </button>
    <input #noteSubmit hidden style="display: none!important" type="file" (change)="onNoteSubmit($event)"/>
    <button mat-button style="margin-left: 10px" class="theme-button" type="button" (click)="automaticMode = false">Registrer transaksjon manuelt</button>
</div>
<ng-container *ngIf="!automaticMode">
    <mat-dialog-content *ngIf="showAsDialog; else nondiag" style="max-height: 100%">
        <ng-container *ngTemplateOutlet="transactionContent"></ng-container>
    </mat-dialog-content>
    <ng-template #nondiag>
        <ng-container *ngTemplateOutlet="transactionContent"></ng-container>
    </ng-template>
    <ng-template #transactionContent>
        <div class="form-container">
            <div class="form-col">
                <h2 class="title" style="padding-bottom: 10px">{{title}}</h2>

                <!--------------------------->
                <!-- Select-instrument row -->
                <!--------------------------->
                <div class="form-row">
                    <div class="row-label">Verdipapir</div>
                    <div class="row-input">
                        <button
                          mat-button
                          id="instrument-button"
                          class="theme-button"
                          type="button"
                          (click)="openSelectInstrumentDialog()"
                        >
                            {{selectedPortfolioItem?.security?.name || 'Velg verdipapir'}}
                        </button>
                    </div>
                </div>

                <form #newTransactionForm="ngForm" class="newTransactionForm">
                    <!-------------->
                    <!-- type row -->
                    <!-------------->
                    <div class="form-row">
                        <div class="row-label">Type</div>
                        <div class="row-input">
                            <mat-button-toggle-group
                              class="theme-toggle"
                                #group="matButtonToggleGroup"
                              [value]="newTransaction.transactionType"
                              (change)="onTypeChange(group.value)"
                            >
                                <mat-button-toggle value="PURCHASE">Kjøp</mat-button-toggle>
                                <mat-button-toggle value="SALE">Salg</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                    <!------------------------->
                    <!-- transactionDate row -->
                    <!------------------------->
                    <div class="form-row">
                        <div class="row-label">Handelsdato</div>
                        <div class="row-input theme-calendar">
                            <p-calendar
                              [(ngModel)]="newTransaction.transactionDate"
                              [showIcon]="!newTransaction.transactionDate"
                              [monthNavigator]="true"
                              [yearNavigator]="true"
                              [yearRange]="getYearRange()"
                              dateFormat="dd.mm.yy"
                              (onSelect)="handleTransactionDateSelect()"
                              [readonlyInput]="true"
                              placeholder="Dato"
                              appendTo="body"
                              required
                              name="transactionDate"
                            ></p-calendar>
                            <button mat-icon-button class="theme-button date-btn add-transaction-btn" type="button"
                              *ngIf="!!newTransaction.transactionDate"
                              (click)="newTransaction.transactionDate = null"
                            >
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                    <!------------------------>
                    <!-- settlementDate row -->
                    <!------------------------>
                    <div class="form-row">
                        <div class="row-label">Oppgjørsdato</div>
                        <div class="row-input theme-calendar">
                            <p-calendar
                              [(ngModel)]="newTransaction.settlementDate"
                              [showIcon]="!newTransaction.settlementDate"
                              [monthNavigator]="true"
                              [yearNavigator]="true"
                              [yearRange]="getYearRange()"
                              dateFormat="dd.mm.yy"
                              name="settlementDate"
                              [readonlyInput]="true"
                              placeholder="Dato"
                              appendTo="body"
                            ></p-calendar>
                            <button mat-icon-button class="theme-button date-btn add-transaction-btn" type="button"
                              *ngIf="!!newTransaction.settlementDate"
                              (click)="newTransaction.settlementDate = null"
                            >
                                <mat-icon>clear</mat-icon>
                            </button>
                        </div>
                    </div>
                    <!-------------------->
                    <!-- fileUpload row -->
                    <!-------------------->
                    <div class="form-row">
                        <div class="row-label">Sluttseddel</div>
                        <div class="row-input">
                            <label id="form-input" class="btn btn-default form-button">
                                <span class="theme-input">
                                    {{newTransaction.note?.fileName || 'Last opp'}}
                                </span>
                                <input
                                  type="file"
                                  (change)="onNoteSelect($event)"
                                  style="display: none!important;"
                                />
                            </label>
                        </div>
                    </div>
                    <!---------------->
                    <!-- noteId row -->
                    <!---------------->
                    <div class="form-row">
                        <div class="row-label">Sluttseddelnr.</div>
                        <mat-form-field floatLabel="never" class="row-input">
                            <input
                              matInput
                              class="form-input"
                              name="note.externalId"
                              [(ngModel)]="newTransaction.note.externalId"
                              placeholder="Sluttseddelnummer"
                            />
                        </mat-form-field>
                    </div>
                    <!---------------->
                    <!-- broker row -->
                    <!---------------->
                    <div class="form-row">
                        <div class="row-label">Megler</div>
                        <div class="row-input">
                            <mat-form-field class="add-transaction-form" appearance="standard">
                                <mat-select name="broker" placeholder="Velg megler" [(ngModel)]="newTransaction.broker.id">
                                    <mat-option [value]="null"></mat-option>
                                    <mat-option *ngFor="let broker of brokers" [value]="broker.id">
                                        {{ broker.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!----------------------------->
                    <!-- transactionCurrency row -->
                    <!----------------------------->
                    <div class="form-row">
                        <div class="row-label">Handelsvaluta</div>
                        <div class="row-input">
                            <mat-form-field class="add-transaction-form" appearance="standard">
                                <mat-select (selectionChange)="newTransaction.settlementCurrency = $event.value" [(ngModel)]="newTransaction.transactionCurrency"
                                  name="transactionCurrency" placeholder="Velg valuta *" required>
                                    <mat-option *ngFor="let currency of currencyOptions" [value]="currency">
                                        {{ currency }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!----------------------------->
                    <!-- settlementCurrency row -->
                    <!----------------------------->
                    <div *ngIf="newTransaction.transactionCurrency && newTransaction.transactionCurrency !== 'NOK'" class="form-row">
                        <div class="row-label">Oppgjørsvaluta</div>
                        <div class="row-input">
                            <mat-form-field class="add-transaction-form" appearance="standard">
                                <mat-select name="settlementCurrency" placeholder="Velg valuta" [(ngModel)]="newTransaction.settlementCurrency">
                                    <mat-option *ngFor="let currency of currencyOptions" [value]="currency">
                                        {{ currency }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!--------------->
                    <!-- units row -->
                    <!--------------->
                    <div class="form-row">
                        <div class="row-label">{{ newTransaction?.portfolioItem?.security?.securityType === 'BOND' ? 'Pålydende' : 'Antall' }}</div>
                        <mat-form-field floatLabel="never" class="row-input">
                            <input
                              matInput
                              class="form-input"
                              name="units"
                              [(ngModel)]="newTransaction.units"
                              pattern="-?[1-9][0-9]*(?:[,.][0-9]+)?"
                              type="text"
                              [placeholder]="newTransaction?.portfolioItem?.security?.securityType === 'BOND' ? 'Pålydende' : 'Antall'"
                              required
                            />
                        </mat-form-field>
                    </div>
                    <!-------------->
                    <!-- fees row -->
                    <!-------------->
                    <div class="form-row">
                        <div class="row-label">Avgifter</div>
                        <mat-form-field floatLabel="never" class="row-input">
                            <input
                              matInput
                              class="form-input"
                              name="fee"
                              [(ngModel)]="newTransaction.fees"
                              pattern="(?:-?[1-9][0-9]*(?:[,.][0-9]+)?|^$|0)"
                              type="text"
                              placeholder="Avgifter"
                            />
                        </mat-form-field>
                    </div>
                    <!------------------------->
                    <!-- interestProfits row -->
                    <!------------------------->
                    <div *ngIf="newTransaction.portfolioItem?.security?.securityType === 'BOND' && newTransaction.transactionType === 'SALE'" class="form-row">
                        <div class="row-label">Renteinntekter</div>
                        <mat-form-field floatLabel="never" class="row-input">
                            <input
                              matInput
                              class="form-input"
                              name="interestProfits"
                              [(ngModel)]="newTransaction.interestProfits"
                              pattern="(?:-?[1-9][0-9]*(?:[,.][0-9]+)?|^$|0)"
                              type="text"
                              placeholder="Renter"
                            />
                        </mat-form-field>
                    </div>
                    <!---------------->
                    <!-- amount row -->
                    <!---------------->
                    <div class="form-row">
                        <div class="row-label">Handelsbeløp</div>
                        <mat-form-field floatLabel="never" class="row-input">
                            <input
                              matInput
                              class="form-input"
                              name="amount"
                              [(ngModel)]="newTransaction.amount"
                              pattern="-?[1-9][0-9]*(?:[,.][0-9]+)?"
                              type="text"
                              [placeholder]="'Beløp ' + (newTransaction.transactionCurrency || '')"
                              required
                            />
                        </mat-form-field>
                    </div>
                    <!-------------------------->
                    <!-- settlementAmount row -->
                    <!-------------------------->
                    <div *ngIf="newTransaction.settlementCurrency && newTransaction.settlementCurrency !== newTransaction.transactionCurrency" class="form-row">
                        <div class="row-label">Oppgjørsbeløp</div>
                        <mat-form-field floatLabel="never" class="row-input">
                            <input
                              matInput
                              class="form-input"
                              [(ngModel)]="newTransaction.settlementAmount"
                              [placeholder]="'Beløp ' + (newTransaction.settlementCurrency || '')"
                              pattern="-?[1-9][0-9]*(?:[,.][0-9]+)?"
                              type="text"
                              [required]="newTransaction.settlementCurrency && newTransaction.settlementCurrency !== newTransaction.transactionCurrency"
                              name="settlementAmount"
                            />
                        </mat-form-field>
                    </div>
                    <!------------------->
                    <!-- amountNOK row -->
                    <!------------------->
                    <div *ngIf="newTransaction.transactionCurrency !== 'NOK' && newTransaction.settlementCurrency && newTransaction.settlementCurrency !== 'NOK'" class="form-row">
                        <div class="row-label">Beløp NOK</div>
                        <mat-form-field floatLabel="never" class="row-input">
                            <input
                              matInput
                              class="form-input"
                              [(ngModel)]="newTransaction.amountNOK"
                              name="amountNOK"
                              pattern="-?[1-9][0-9]*(?:[,.][0-9]+)?"
                              type="text"
                              placeholder="Beløp NOK"
                            />
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <div *ngIf="newTransaction.note?.content" class="form-col">
                <my-note [note]="newTransaction.note" style="max-width: 900px;"></my-note>
            </div>
        </div>
        <!---------------->
        <!-- submit row -->
        <!---------------->
        <div class="submit-row">
            <button
              mat-button
              class="theme-submit form-button"
              type="submit"
              (click)="submitNewTransaction(); newTransactionForm.reset();"
              [disabled]="!newTransactionForm.form.valid || !newTransactionForm.form.dirty || !typeSelected"
            >
                <mat-icon aria-label="Bekreft">check</mat-icon>
                Lagre
            </button>
            <button
              mat-button
              *ngIf="showAsDialog"
              id="delete-button"
              class="theme-button form-button"
              type="button"
              (click)="deleteTransaction()"
              [disabled]="!newTransaction || !newTransaction.id"
            >
                <mat-icon aria-label="Bekreft">delete</mat-icon>
                Slett
            </button>
        </div>
    </ng-template>
</ng-container>
