import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { AuthService } from './auth.service';

import { Observable, throwError, EMPTY } from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (`${localStorage.getItem('access_token')}`) {
      return next.handle(this.setAuthorizationHeader(req)).pipe(
                 catchError((event) => {
                   if (event instanceof HttpErrorResponse) {
                     return this.catch401(event);
                   }
                 }));
    }
    else {  // User not authenticated, forward the response without changes
      return next.handle(req);
    }
  }

// Request Interceptor to append Authorization Header
  private setAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
      return req.clone({ setHeaders: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } });
  }

  // Response Interceptor
  private catch401(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      this.authService.logout();
      return EMPTY;
    }

    return throwError(error);
  }
}
