export class Note {
  id: number;
  externalId: string;
  contentType: {
    type: string;
    subtype: string;
  };
  content: Blob | File | string;
  fileName: string;

  setContentTypeFromString(contentType: string): void {
    contentType = contentType.replace(/;.*/g, '');
    const splitterIndex = contentType.indexOf('/');
    if (splitterIndex === -1) {
      throw "Invalid contentType";
    }
    this.contentType = {
      type: contentType.substring(0, splitterIndex),
      subtype: contentType.substring(splitterIndex + 1)
    };
  }
}
