<p-table
  #transactionsTable
  *ngIf="displayMode !== displayModeDict.inbox || (transactions && transactions.length > 0)"
  [value]="transactions"
  [columns]="tableColumns"
  [responsive]="true"
  [autoLayout]="true"
  [paginator]="showPaginator"
  [totalRecords]="totalRecords"
  [rowsPerPageOptions]="[15, 25, 50, 100]"
  [pageLinks]="5"
  [rows]="rowsPerPage"
  [lazy]="true"
  [lazyLoadOnInit]="false"
  (onLazyLoad)="lazyLoadTransactions($event)"
  [rowHover]="true"
  (onRowUnselect)="handleRowSelect($event.data)"
  (onRowSelect)="handleRowSelect($event.data)"
  selectionMode="single"
  [tableStyleClass]="tableStyleClass"
  paginatorDropdownAppendTo="body"
  styleClass="defaultRowStyleClass"
>
    <ng-template *ngIf="displayMode === displayModeDict.all || displayMode === displayModeDict.security" pTemplate="caption">
        <div style="text-align: left;">
            <i class="fa fa-search" style="margin:4px 4px 0 0;"></i>
            <input class="globalfilter" type="text" pInputText size="50" placeholder="Søk"
              (input)="transactionsTable.filterGlobal($event.target.value, 'contains')" style="width:auto;"
            >
        </div>
        <div *ngIf="globalsService.escaliMode && displayMode !== displayModeDict.transaction">
            <p-dropdown (onChange)="getFilters(null, true)" [(ngModel)]="selectedOrg" [options]="orgOptions" appendTo="body"
              maxlength="20" optionLabel="name" panelStyleClass="table-dropdown-panel" placeholder="Velg klient" showClear="true" styleClass="table-dropdown">
            </p-dropdown>
        </div>
        <div class="header-item">
            <button (click)="exportTransactionsToExcel()" class="theme-button" id="export-button" mat-button type="button">
                <mat-icon aria-label="Last ned">cloud_download</mat-icon>  Last ned
            </button>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col1 of columns">
                <!-- Sortable header col -->
                <ng-container *ngIf="transactions?.length > 1 && col1.type !== 'editButton'; else nonSortableCol">
                    <th [pSortableColumn]="col1.field">
                        <p-sortIcon *ngIf="transactions?.length > 1" [field]="col1.field"></p-sortIcon>
                        {{col1.header}}
                    </th>
                </ng-container>
                <!-- Non-sortable header col-->
                <ng-template #nonSortableCol>
                    <th>{{col1.header}}</th>
                </ng-template>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData" [ngClass]="getRowClasses(rowData)">
            <td *ngFor="let col2 of columns" [ngSwitch]="col2.type">
                <ng-container *ngSwitchCase="'assetName'">
                    <p *ngIf="getCellData(rowData, col2.field); else empty">
                        {{getCellData(rowData, col2.field)}}
                    </p>
                    <ng-template #empty>
                        <p style="white-space: nowrap;">Ukjent verdipapir</p>
                    </ng-template>
                </ng-container>
                <p *ngSwitchCase="'transactionType'" [ngClass]="getCellData(rowData, col2.field)">
                    <span *ngIf="getCellData(rowData, col2.field) === 'PURCHASE'">Kjøp</span>
                    <span *ngIf="getCellData(rowData, col2.field) === 'SALE'">Salg</span>
                    <span *ngIf="getCellData(rowData, col2.field) === 'DIVIDEND'">Utbytte</span>
                </p>
                <p *ngSwitchCase="'date'" class="dateCell" style="text-align: right;">
                    {{getCellData(rowData, col2.field) | date}}
                </p>
                <p *ngSwitchCase="'datetime'" class="dateCell" style="text-align: right;">
                    {{getCellData(rowData, col2.field) | date:'dd.MM.yyyy HH:mm'}}
                </p>
                <p *ngSwitchCase="'decimal'" style="text-align: right;">
                    {{getCellData(rowData, col2.field) | number: '1.2-2'}}
                </p>
                <p *ngSwitchCase="'integer'" style="text-align: right;">
                    {{getCellData(rowData, col2.field) | number: '1.0-0'}}
                </p>
                <p style="text-align: center;"></p>
                <button
                  *ngSwitchCase="'editButton'"
                  mat-icon-button
                  id="edit-button"
                  [disableRipple]="false"
                  type="button"
                  (click)="openTransactionEditDialog(rowData); $event.stopPropagation()"
                >
                    <mat-icon [inline]="true" aria-label="Rediger" style="font-family: 'Material Icons', sans-serif;">edit</mat-icon>
                </button>
                <p *ngSwitchDefault>
                    {{getCellData(rowData, col2.field)}}
                </p>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="tableColumns.length">
                {{hasError ? 'Feil ved henting av data' : isComplete ? 'Ingen data funnet' : 'Henter data...'}}
            </td>
        </tr>
    </ng-template>
</p-table>
