import { Broker } from './broker';

export class Customer {
  id: number;
  name: string;
  organization?: Array<Organization>;
  organizationNumber: number;
  email?: string;
  promptForExchangeRates?: boolean;
  usingCustodialAccount?: boolean;
  integrationSettings?: IntegrationSetting;
  bankAccountSettings?: Array<LedgerAccountSetting>;
  ledgerAccountSettings: Array<LedgerAccountSetting>;
  organizationMappings?: Array<OrganizationMapping>;
}

export class IntegrationSetting {
  constructor() {
    this.accountingSystem = new AccountingSystem();
  }

  accountingSystem: AccountingSystem;
  enabled: boolean;
  autoExport: boolean;
  clientKey?: string;
  username?: string;
  password?: string;
}

export class AccountingSystem {
  id: number;
  name?: string;
}

export class LedgerAccountSetting {
  id: number;
  accountNumber: number;
  ledgerAccount: LedgerAccount;
  broker?: Broker;
  currency?: string;
}

export class LedgerAccount {
    id?: number;
    accountCategory?: string;
    description?: string;
    domestic?: boolean;
    exemptionMethod?: boolean;
    securityType: string;
    transactionPosition?: string;
    shortName?: string;
}

export interface CompareById {
  id?: number;
}

export class OrganizationMapping implements CompareById {
  id?: number;
  broker: Broker;
  organization: Organization;
  identifier: string;
  isValid = () => !!this.broker && !!this.broker.id && !!this.organization && !!this.organization.id && !!this.identifier;

  constructor(object?: any) {
    if (!!object) {
      Object.assign(this, object);
    }
    else {
      this.broker = new Broker();
      this.organization = new Organization();
    }
  }
}

export class Organization implements CompareById {
  id?: number;
  name: string;
  noteToAddress?: string;
  organizationNumber?: number;
  requireOrgNumber?: boolean;
  isValid = () => this.name && (!this.requireOrgNumber || this.organizationNumber != null);

  constructor(object?: any) {
    if (object) {
      Object.assign(this, object);
      this.requireOrgNumber = false;
    }
    else {
      this.requireOrgNumber = true;
    }
  }
}

export const accountingSystems: Array<AccountingSystem> = [
  {
    id: 1,
    name: 'PowerOffice Go'
  },
  {
    id: 2,
    name: 'Fiken',
  },
  {
    id: 3,
    name: 'Maestro',
  },
  {
    id: 4,
    name: 'Visma'
  }
];

export const currencies = ['NOK', 'SEK', 'DKK', 'EUR', 'USD'];
