import { Security } from "./security";

export class PortfolioItem {
  security: Security;
  exemptionMethod?: boolean;
  units: number;
  cost: number;
  costPerUnit: number;

  constructor() {
    this.security = new Security();
  }
}
