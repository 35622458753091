import { Component } from '@angular/core';
import { DisplayMode } from './domain/display-mode';

@Component({
  selector: 'asset-details',
  templateUrl: './asset-details.component.html'
})

export class AssetDetailsComponent {
  public displayModeSecurity = DisplayMode.SECURITY;
}
