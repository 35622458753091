export enum DisplayMode {
    ALL,
    SECURITY,  // All transactions for a single security
    TRANSACTION,  // A single transaction
    SECURITY_SETTINGS,
    INBOX
}

// Used when displayMode checks are required in templates, where enums checks can't be used
export class DisplayModeDict {
    readonly all;
    readonly security;
    readonly transaction;
    readonly securitySettings;
    readonly inbox;

    constructor() {
        this.all = DisplayMode.ALL;
        this.security = DisplayMode.SECURITY;
        this.transaction = DisplayMode.TRANSACTION;
        this.securitySettings = DisplayMode.SECURITY_SETTINGS;
        this.inbox = DisplayMode.INBOX;
    }
}
