import { Filter } from './filter';
import { Page } from './page';

export type RequestArgTuple = [Page<any>, Array<Filter>];

export class RequestArguments<T> {
    page?: Page<T>;
    filters?: Array<Filter>;

    constructor(page?: Page<T>, filters?: Array<Filter>) {
        this.page = page || new Page();
        this.filters = filters || [];
    }

    // firstChar: One of '?', '&', ''
    getQueryParams(firstChar: string): string {
        if (!this.filters || this.filters.length === 0) {
            if (!this.page) {
                return '';
            }
            return this.page.getQueryParams(firstChar);
        }
        this.sortFilters(this.filters);
        let params = '';
        this.filters.forEach(filter => {
            const param = filter.getQueryString(firstChar || '');
            if (param) {
                params += param;
                firstChar = '&';
            }
        });
        if (!this.page) {
            return params;
        }
        return params + this.page.getQueryParams(firstChar);
    }

    argumentsEquals(other: RequestArguments<T>): boolean {
        if (!other) {
            return false;
        }
        if (this === other) {
            return true;
        }
        return this.getQueryParams('') === other.getQueryParams('');
    }

    sortFilters(filters: Array<Filter>): Array<Filter> {
        return filters.sort((f1, f2) => f1.key + f1.value > f2.key + f2.value ? 1 : -1);
    }
}
