import { Component, OnInit } from '@angular/core';

import { AuthService } from './auth.service';

@Component({
             template: `
               <!-- Display loading sprinner until user is redirected to auth0 login -->
               <div class="loading" style="margin-top: 30%; margin-bottom: 20%">
                 <div class="logo"></div>
                 <svg class="spinner" viewBox="25 25 50 50">
                   <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"></circle>
                 </svg>
               </div>
             `
           })

export class LoginComponent implements OnInit {

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    this.authService.login();
  }
}
