import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Note } from './domain/note';


@Component({
  selector: 'my-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})

export class NoteComponent {

  public sanitizedPdfUrl: SafeResourceUrl;
  public unsanitizedPdfUrl: string;
  public browserNativePdfSupport = true;
  public isPdf = false;

  public hasError = false;
  public isComplete = false;
  public _note: Note;

  @Input() set note(note: Note) {
    this._note = note;
    this.browserNativePdfSupport = navigator.userAgent.indexOf('Edge') === -1 && navigator.userAgent.indexOf('MSIE') === -1;
    if (this._note.contentType && this._note.contentType.type && this._note.contentType.subtype) {
      this.isPdf = this._note.contentType.type === 'application' && this._note.contentType.subtype === 'pdf';
    }
    else if (this._note.content instanceof File) {
      this.isPdf = (this._note.content as File).type === 'application/pdf';
    }
    else {
      this.hasError = true;
      this.isComplete = true;
    }
    if (this.isPdf) {
      this.unsanitizedPdfUrl = window.URL.createObjectURL(new Blob([this._note.content], {type: 'application/pdf'}));
      this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.unsanitizedPdfUrl);
      this.hasError = false;
    }
    else if (!this._note.content) {
      this.hasError = true;
    }
    else {
      if (this._note instanceof File) {

      }
      this.isComplete = true;
    }
  }

  constructor(public sanitizer: DomSanitizer) { }
}
