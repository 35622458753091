import { Component, OnInit, Input } from '@angular/core';
import { TableColumn } from "../domain/table-column";

import { BalanceService } from './balance.service';
import { Observable } from 'rxjs';
import { LedgerAccount } from '../domain/ledger-account';


@Component({
  selector: 'my-balance',
  template: `
      <div
              style="margin-bottom: 20px;"
      >
          <p-table
            [value]="accounts | async"
            [columns]="tableColumns"
            [responsive]="true"
            [autoLayout]="true"
            tableStyleClass="customStripes"
            styleClass="defaultRowStyleClass"
          >
              <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col1 of columns" [pSortableColumn]="col1.field">
                      <p-sortIcon *ngIf="(accounts | async)?.length > 1" [field]="col1.field"></p-sortIcon>
                      {{col1.header}}
                    </th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                      <td *ngFor="let col2 of columns" [ngSwitch]="col2.type">
                          <p *ngSwitchCase="'integer'" style="text-align: right">
                              {{rowData[col2.field] | number: '1.0-0'}}
                          </p>
                          <p *ngSwitchDefault>
                              {{rowData[col2.field]}}
                          </p>
                      </td>
                  </tr>
              </ng-template>
          </p-table>
      </div>
  `
})

export class BalanceComponent implements OnInit {
  @Input() displayResultAccounts = false;
  @Input() displayColumns: Array<string>;
  @Input() globalFilter = false;

  public accounts: Observable<Array<LedgerAccount>>;
  public tableColumns: Array<TableColumn> = [
    {header: 'KONTO', field: 'accountNumber'},
    {header: 'BESKRIVELSE', field: 'shortName'},
    {header: 'SALDO', field: 'currentBalance', type: 'integer'}
  ];

  constructor(private balanceService: BalanceService) { }

  ngOnInit() {
    if (this.displayResultAccounts) {
      this.accounts = this.balanceService.getResult();
    }
    else {
      this.accounts = this.balanceService.getBalance();
    }
  }
}
