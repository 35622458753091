<h5 class="title" id="dialog-header" style="text-align: center">
    Verdipapir-innstillinger
</h5>
<mat-dialog-content>
    <div id="dialog-container">
        <my-portfolio
          [displayMode]="displayModeSettings"
        ></my-portfolio>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix" style="justify-content: center">
    <button mat-button mat-dialog-close class="theme-button" type="button">Ok</button>
</mat-dialog-actions>
