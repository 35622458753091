import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AbstractTableComponent } from '../abstract-table.component';
import { DisplayMode, DisplayModeDict } from '../domain/display-mode';
import { Filter, Op } from '../domain/filter';
import { Page } from '../domain/page';
import { PortfolioItem } from "../domain/portfolio-item";
import { RequestArguments } from '../domain/request-arguments';
import { TableColumn } from "../domain/table-column";
import { GlobalsService } from '../globals.service';

import { PortfolioService } from './portfolio.service';

export interface FilterValues {
  global?: string;
  hideZeroBalance?: boolean;
  toDate?: Date;
  isin?: string;
}

export interface CellEditCompleteEvent {
  field: string;
  data: any;
  originalEvent: Event;
}
////////////////////////////
// Cell edit error dialog //
////////////////////////////
@Component({templateUrl: 'cell-edit-error-dialog.component.html'})
export class CellEditErrorDialogComponent {
  reload() {
    location.reload();
  }
}
////////////////////
// Main component //
////////////////////
@Component({
  selector: 'my-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['../abstract-table.component.scss', './portfolio.component.scss']
})

export class PortfolioComponent extends AbstractTableComponent<PortfolioItem> implements OnInit {
  public displayModeDict = new DisplayModeDict();
  public templateValues = {globalFilter: '', toDateFilter: undefined as Date, unitsFilterChecked: false};
  public tableColumns: Array<TableColumn> = [
    {header: 'VERDIPAPIR', field: 'security.name'},
    {header: 'TICKER', field: 'security.ticker'},
    {header: 'TYPE', field: 'security.securityType', type: 'securityType'},
    {header: 'ISIN', field: 'security.isin'},
    {header: 'ANTALL', field: 'units', type: 'integer'},
    {header: 'PRIS PER ENHET', field: 'costPerUnit', type: 'decimal'},
    {header: 'KOSTPRIS', field: 'cost', type: 'integer'}
  ];
  public rowsPerPage = 15;
  public showPaginator = false;
  public totalRecords: number;
  public hasError = false;
  public isComplete = false;
  public portfolio: Array<PortfolioItem> = [];
  public filterValues: FilterValues = {hideZeroBalance: true};
  public filterSubject = new Subject<Array<Filter>>();
  public defaultPage = new Page<PortfolioItem>(15, 0, 'cost', 'desc');
  public pageSubject = new BehaviorSubject<Page<PortfolioItem>>(this.defaultPage);

  @Input() displayMode = DisplayMode.ALL;
  @Output() securityName = new EventEmitter<string>();

  constructor(private portfolioService: PortfolioService,
              private route: ActivatedRoute,
              public dialog: MatDialog,
              public globalsService: GlobalsService,
              private titleService: Title
  ) {
    super(dialog);
  }

  ngOnInit() {
    if (this.globalsService.escaliMode) {
      this.tableColumns.splice(5);  // Remove cost and costPerUnit cols
    }
    if (this.displayMode === DisplayMode.SECURITY) {
      this.filterValues.isin = this.route.snapshot.params.isin;
    }
    else if (this.displayMode === DisplayMode.SECURITY_SETTINGS) {
      this.tableColumns = [
        this.tableColumns[0],
        this.tableColumns[1],
        this.tableColumns[3],
        {header: 'FRITAK', field: 'exemptionMethod', type: 'exemptionMethod'}
      ];
      this.filterValues.hideZeroBalance = false;
    }
    else {
      // Display security name as a link to its assetDetails page
      this.tableColumns[0].type = 'assetLink';
    }

    // Combine emissions from pageSubject and filterSubject into a requestArgs object
    combineLatest([
      this.pageSubject,
      this.filterSubject.pipe(
        startWith(this.updateFilterValues()),
        debounceTime(400),
        // Creating two RequestArgument objects here only to use their argumentEquals method to check for equality.
        distinctUntilChanged((x: Array<Filter>, y: Array<Filter>) => new RequestArguments(undefined, x).argumentsEquals(new RequestArguments(undefined, y))
        )
      )
    ])
      .pipe(
        tap(() => {
          this.isComplete = false;
          this.hasError = false;
        }),
        map(([page, filters]) => new RequestArguments(page, filters)),
        switchMap(args => this.portfolioService.getPortfolio(args))
      ).subscribe(
      portfolioPage => {
        this.totalRecords = portfolioPage.totalElements;
        this.showPaginator = this.showPaginator || this.totalRecords > this.rowsPerPage;  // Prevents hiding of paginator after changing rowsPerPage
        this.portfolio = portfolioPage.content;
        this.hasError = false;
        this.isComplete = true;
        if (this.displayMode === DisplayMode.SECURITY || this.displayMode === DisplayMode.TRANSACTION) {
          this.titleService.setTitle(this.portfolio?.[0]?.security?.name || this.route.snapshot.params.isin);
        }
      },
      error => {
        console.log("Error getting portfolio:");
        console.log(error);
        this.portfolio = [];
        this.hasError = true;
        this.isComplete = true;
      }
    );

    // Underlying data has changed => reload data from service
    this.portfolioService.portfolioChangedNotifier
      .subscribe(() => this.lazyLoadPortfolio({}));
  }

  lazyLoadPortfolio(event: LazyLoadEvent) {
    this.rowsPerPage = event.rows;
    this.updateFilterValues({global: event.globalFilter});

    // This wierd check is due to event.sortOrder being set to 1/asc even though user hasn't overridden initial sort
    const order = event.sortField ? event.sortOrder : this.defaultPage.sortOrder;

    this.pageSubject.next(new Page(event.rows, event.first, event.sortField || this.defaultPage.sortField, order));
  }

  updateFilterValues(newValues: FilterValues = {}): Array<Filter> {
    if (newValues.global !== undefined) {
      this.filterValues.global = newValues.global;
    }
    if (newValues.hideZeroBalance != null) {
      this.filterValues.hideZeroBalance = newValues.hideZeroBalance;
    }
    if (newValues.toDate !== undefined) {
      this.filterValues.toDate = newValues.toDate;
      this.templateValues.toDateFilter = newValues.toDate;
      if (newValues.toDate !== null) {
        // Reset filters on aggregated values, which are not supported for portfolio snapshots
        this.filterValues.global = null;
        this.filterValues.hideZeroBalance = false;
        this.templateValues.globalFilter = '';
        this.templateValues.unitsFilterChecked = true;
      }
    }
    const newFilters = [
      new Filter('global', undefined, this.filterValues.global),
      new Filter('security.isin', undefined, this.filterValues.isin),
      // when condition in value field is true, filter becomes units=ne(0).
      new Filter('units', Op.ne, this.displayMode === DisplayMode.ALL && this.filterValues.hideZeroBalance ? 0 : null),
      new Filter('toDate', undefined, this.parseDate(this.filterValues.toDate))
    ];
    this.filterSubject.next(newFilters);
    return newFilters;
  }

  handleCellEdit(event: CellEditCompleteEvent) {
    console.log("event:");
    console.log(event);
    this.portfolioService.savePortfolioItem(event.data)
      .subscribe(
        res => {
          if (!res) {
            this.dialog.open(CellEditErrorDialogComponent);
          }
        },
        () => this.dialog.open(CellEditErrorDialogComponent)
      );
  }

  exportPortfolioToExcel() {
    const currentPage = this.pageSubject.getValue() || this.defaultPage;
    const hugePage = new Page<PortfolioItem>(9999999, 0, currentPage.sortField, currentPage.sortOrder);
    this.exportDataToExcel(this.portfolioService.getPortfolio(new RequestArguments(hugePage, this.updateFilterValues())), "portefølje", this.tableColumns);
  }
}
