import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(): boolean {
    // If user isn't yet authenticated, it's possible that the initial auth process hasn't yet completed.
    // In that case, return the first future auth result. Redirect user to login if current or future auth result is false
    if (this.auth.isAuthenticated()) {
      return true;
    }
    else {
      this.auth.authResult
        .pipe(
          first(),
          catchError(() => of(false)),
          tap(result => {
            if (!result) {
              this.auth.login();
              return false;
            }
          })
        )
        .subscribe();
    }
  }
}
