import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AccountsService } from './accounts.service';

@Injectable()
export class SettingsInterceptor implements HttpInterceptor {

  constructor(private accountsService: AccountsService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.accountsService.getSelectedCustomerId()) {
      return next.handle(
        // Add customer param to request
        req.clone({url: req.url + (req.url.indexOf('?') === -1 ? '?' : '&') + 'customerId=' + this.accountsService.getSelectedCustomerId()})
      );
    }
    else {  // Customer not selected. Forward request without changes.
      return next.handle(req);
    }
  }
}
