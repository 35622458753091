<h2 style="margin-top: 20px" class="title">Regnskapstransaksjoner</h2>
<p-table
  #ledgerTable
  [value]="ledgerEntries"
  [columns]="tableColumns"
  [responsive]="true"
  [autoLayout]="true"
  [paginator]="showPaginator"
  [totalRecords]="totalRecords"
  [rowsPerPageOptions]="[20, 40, 60, 100]"
  [pageLinks]="5"
  [rows]="rowsPerPage"
  [lazy]="true"
  [lazyLoadOnInit]="false"
  (onLazyLoad)="lazyLoadLedgerEntries($event)"
  paginatorDropdownAppendTo="body"
  tableStyleClass="customStripes"
  styleClass="defaultRowStyleClass"
>
    <ng-template *ngIf="displayMode === displayModeAll" pTemplate="caption">
        <div style="text-align: left">
            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
            <input class="globalfilter" type="text" pInputText size="50" placeholder="Søk"
              (input)="ledgerTable.filterGlobal($event.target.value, 'contains')" style="width:auto"
            >
        </div>
        <div *ngFor="let key of ['fromDate', 'toDate']" class="theme-calendar header-item">
            <p-calendar
              dateFormat="dd.mm.yy"
              (onSelect)="updateFilterValues(createFilterObject(key, $event))"
              [(ngModel)]="templateValues[key]"
              dataType="string"
              [defaultDate]="key === 'fromDate' ? getLastYearStart() : getLastYearEnd()"
              [showIcon]="!templateValues[key]"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [yearRange]="getYearRange()"
              name="toDate"
              [readonlyInput]="true"
              [placeholder]="key === 'fromDate' ? 'Fra dato' : 'Til dato'"
              appendTo="body"
            ></p-calendar>
            <button mat-icon-button class="theme-button date-btn" type="button"
              *ngIf="!!templateValues[key]"
              (click)="updateFilterValues(createFilterObject(key, null))"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        <div class="header-item">
            <button (click)="exportLedgerToExcel()" class="theme-button" id="export-button" mat-button type="button">
                <mat-icon aria-label="Last ned">cloud_download</mat-icon>  Last ned
            </button>
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <ng-container *ngFor="let col1 of columns">
                <!-- Sortable header col -->
                <ng-container *ngIf="ledgerEntries?.length > 1 && col1.type !== 'detailsButton'; else nonSortableCol">
                    <th [pSortableColumn]="col1.field">
                        <p-sortIcon [field]="col1.field"></p-sortIcon>
                        {{col1.header}}
                    </th>
                </ng-container>
                <!-- Non-sortable header col-->
                <ng-template #nonSortableCol>
                    <th>{{col1.header}}</th>
                </ng-template>
            </ng-container>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [ngClass]="{'withDetailsButton': displayMode === displayModeAll}">
            <td *ngFor="let col2 of columns" [ngSwitch]="col2.type">
                <ng-container *ngIf="rowData.transaction?.portfolioItem?.security?.isin">
                    <a
                      *ngSwitchCase="'assetLink'"
                      [routerLink]="['/details/', rowData.transaction?.portfolioItem?.security?.isin]"
                      class="tableLink"
                    >
                        {{getCellData(rowData, col2.field)}}
                    </a>
                </ng-container>
                <p *ngSwitchCase="'transactionType'" [ngClass]="getCellData(rowData, col2.field)">
                    <span *ngIf="getCellData(rowData, col2.field) === 'PURCHASE'">Kjøp</span>
                    <span *ngIf="getCellData(rowData, col2.field) === 'SALE'">Salg</span>
                </p>
                <p *ngSwitchCase="'date'" class="dateCell" style="text-align: right">
                    {{getCellData(rowData, col2.field) | date}}
                </p>
                <p *ngSwitchCase="'decimal'" style="text-align: right">
                    {{getCellData(rowData, col2.field) | number: '1.2-2'}}
                </p>
                <p *ngSwitchCase="'integer'" style="text-align: right">
                    {{getCellData(rowData, col2.field) | number: '1.0-0'}}
                </p>
                <p style="text-align: center"></p>
                <button
                  *ngSwitchCase="'detailsButton'"
                  mat-icon-button
                  id="details-button"
                  [disableRipple]="false"
                  type="button"
                  (click)="openTransactionDetails(rowData.transaction.id)"
                >
                    <mat-icon [inline]="true" aria-label="Vis detaljer" style="font-family: 'Material Icons', sans-serif;">open_in_new</mat-icon>
                </button>
                <p *ngSwitchDefault>
                    {{getCellData(rowData, col2.field)}}
                </p>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr>
            <td [attr.colspan]="tableColumns.length">
                {{hasError ? 'Feil ved henting av data' : isComplete ? 'Ingen data funnet' : 'Henter data...'}}
            </td>
        </tr>
    </ng-template>
</p-table>
