import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from "./hmr";

// Primeng stylesheets
// import '../node_modules/primeng/resources/themes/bootstrap/theme.css';
// import '../node_modules/primeng/resources/primeng.min.css';
//
// import '../node_modules/bootstrap3/dist/css/bootstrap.css';

if (environment.production || environment.test) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  }
  else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
}
else {
  bootstrap().catch(err => console.log(err));
}
