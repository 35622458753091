/////////////////////////////////
// Select instrument component //
/////////////////////////////////
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LazyLoadEvent } from 'primeng/api';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take, tap} from 'rxjs/operators';
import { AbstractTableComponent } from '../../abstract-table.component';
import { Filter } from '../../domain/filter';
import { Page } from '../../domain/page';
import { RequestArguments } from '../../domain/request-arguments';
import { Security } from '../../domain/security';
import { TableColumn } from '../../domain/table-column';
import { PortfolioService } from '../../portfolio/portfolio.service';
import { AddInstrumentDialogComponent } from './add-instrument-dialog.component';

@Component({
  selector: 'select-instrument-dialog',
  templateUrl: 'select-instrument-dialog.component.html',
  styleUrls: ['select-instrument-dialog.component.scss']
})
export class SelectInstrumentDialogComponent extends AbstractTableComponent<Security> implements OnInit {
  public tableColumns: Array<TableColumn> = [
    {header: 'VERDIPAPIR', field: 'name'},
    {header: 'TICKER', field: 'ticker'},
    {header: 'ISIN', field: 'isin'}
  ];
  public rowsPerPage = 15;
  public hasError = false;
  public isComplete = false;
  public totalRecords: number;
  public securities: Array<Security>;
  public filterSubject = new Subject<Array<Filter>>();
  public pageSubject = new Subject<Page<Security>>();
  public defaultPage = new Page<Security>(15, 0, 'id', 'desc');

  constructor(
    private selfDialogRef: MatDialogRef<SelectInstrumentDialogComponent>,
    private portfolioService: PortfolioService,
    public securitiesDialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    combineLatest([
      this.pageSubject.pipe(startWith(this.defaultPage)),
      this.filterSubject
        .pipe(
          startWith([new Filter('global', undefined, undefined)]),
          debounceTime(400),
          distinctUntilChanged((x: Array<Filter>, y: Array<Filter>) =>
            x[0].getQueryString('') === y[0].getQueryString('')
          )
        )
    ])
      .pipe(
        tap(() => {
          this.isComplete = false;
          this.hasError = false;
        }),
        map(([page, filters]) => new RequestArguments(page, filters)),
        switchMap(args => this.portfolioService.getSecurities(args))
      ).subscribe(
      securitiesPage => {
        this.totalRecords = securitiesPage.totalElements;
        this.securities = securitiesPage.content;
        this.hasError = false;
        this.isComplete = true;
      },
      error => {
        console.log("Error getting ledger:");
        console.log(error);
        this.securities = [];
        this.hasError = true;
        this.isComplete = true;
      }
    );
  }

  lazyLoadSecurities(event: LazyLoadEvent) {
    this.rowsPerPage = event.rows;

    // This wierd check is due to event.sortOrder being set to 1/asc even though user hasn't overridden initial sort
    const order = event.sortField ? event.sortOrder : 'desc';

    this.pageSubject.next(new Page(event.rows, event.first, event.sortField || this.defaultPage.sortField, order));
    this.filterSubject.next([new Filter('global', undefined, event.globalFilter)]);
  }

  onSecuritySelection(event): void {
    this.selfDialogRef.close({'data': {security: event.data}});
  }

  openAddInstrumentDialog() {
    const addInstrumentDialogRef = this.securitiesDialog.open(AddInstrumentDialogComponent);
    addInstrumentDialogRef.afterClosed()
      .pipe(
        take(1)
      )
      .subscribe(
        result => {
          if (result) {  // Check necessary in case user dismissed dialog
            this.selfDialogRef.close({'data': result});
          }
        },
        () => {
          location.reload();  // This shouldn't happen - reload and reset all values
        }
      );
  }
}
